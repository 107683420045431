import { FC, useState } from 'react';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { SendEmailMC } from '../SendEmailMC';
import { AddAddresseeMC } from '../AddAddresseeMC';
import { MCType } from '../../types';
import { UserListItem } from 'src/features/_card_lists/types';
import { useVacationPageContext } from 'src/pages/vacation/ui/VacationPage/context/useGroupUsersContext';
import { Status } from '../../consts';

interface Props {
	modalSendEmail: boolean;
	toggleModalSendEmail: () => void;
}

export const SendMailModal: FC<Props> = props => {
	const {
		modalSendEmail, //
		toggleModalSendEmail,
	} = props;

	// * Context
	const { users } = useVacationPageContext();

	const allUsers: UserListItem[] = users.map(item => ({
		id: item.id,
		name: item.name,
		position: '', // TODO
		avatar: undefined, // TODO
		status: item.vacations && item.vacations.length > 0 ? Status.True : Status.False,
	}));

	const [activeMC, setActiveMC] = useState<MCType>('email');

	const [tempSelectedUserIds, setTempSelectedUserIds] = useState<string[]>([]);
	const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
	const removeUser = (userId: string) => {
		setTempSelectedUserIds(prevState => prevState.filter(selectedUserId => selectedUserId !== userId));
		setSelectedUserIds(prevState => prevState.filter(selectedUserId => selectedUserId !== userId));
	};

	const MC = {
		email: (
			<SendEmailMC
				allUsers={allUsers}
				toggleModal={toggleModalSendEmail}
				setActiveMC={setActiveMC}
				selectedUserIds={selectedUserIds}
				removeUser={removeUser}
			/>
		),
		addressee: (
			<AddAddresseeMC
				allUsers={allUsers}
				setActiveMC={setActiveMC}
				tempSelectedUserIds={tempSelectedUserIds}
				setTempSelectedUserIds={setTempSelectedUserIds}
				selectedUserIds={selectedUserIds}
				setSelectedUserIds={setSelectedUserIds}
			/>
		),
	};

	// * Render
	return (
		<ModalNewProto
			isOpen={modalSendEmail}
			onClose={toggleModalSendEmail}
			width="l"
		>
			{MC[activeMC]}
		</ModalNewProto>
	);
};
