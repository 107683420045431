import { useGetDepartmentFindQuery } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { UserListItem } from 'src/features/_card_lists/types';
import { AddUsersMC } from 'src/features/_modal_contents/AddUsersMC';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';

interface Props {
	showModal: boolean;
	toggleModal: () => void;
	editAction: (userIds: string[]) => void;
	editLoading: boolean;
	allUsers: UserListItem[];
}

export const AddUserContainer: React.FC<Props> = props => {
	const {
		showModal, //
		toggleModal,
		editAction,
		editLoading,
		allUsers,
	} = props;

	// * API
	const { data: departmentData, isLoading: isLoadingDepartments } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const allDepartments = departmentData?.body ?? [];
	const isLoading = editLoading || isLoadingDepartments;

	// * Render
	return (
		<>
			{showModal && (
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
					width="l"
				>
					<AddUsersMC
						title="Добавить сотрудников"
						allUsers={allUsers}
						allDepartments={allDepartments}
						setSelectedUsers={editAction}
						isLoading={isLoading}
						toggleModal={toggleModal}
					/>
				</ModalNewProto>
			)}
		</>
	);
};
