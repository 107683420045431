import React, { ChangeEvent, HTMLAttributes } from 'react';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg/action/close.svg';
import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import styles_input from 'src/shared/ui/_inputs/_styles/_input.module.scss';
import { IconButton } from '../../../_buttons/IconButton';
import { LoaderCircle } from '../../../_loaders/LoaderCircle/LoaderCircle';

interface Props extends HTMLAttributes<HTMLDivElement> {
	placeholder: string;
	value: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	active?: boolean; // active true disables
	onActiveClick?: () => void;
	disabled?: boolean;
	loading?: boolean;
	inputId?: string;
}

export const SearchBar: React.FC<Props> = props => {
	const {
		className, //
		placeholder,
		value,
		onChange,
		active = false,
		onActiveClick,
		onKeyDown,
		loading,
		inputId,
	} = props;

	return (
		<div className={`${styles_input.input_wrapper} ${active && styles_input.active} ${className}`}>
			<input
				className={styles_input.input}
				type="text"
				value={value}
				onChange={onChange}
				onKeyDown={onKeyDown}
				id={inputId}
			/>
			{loading ? (
				// || status === RequestStatus.failed // ! HACK - canceling request reject after a nwe request starts pending causing loading disappear.
				<div className={styles_input.icon_right}>
					<LoaderCircle />
				</div>
			) : active ? (
				<div className={styles_input.icon_right}>
					<IconButton
						Icon={<CloseSVG />}
						onClick={onActiveClick}
					/>
				</div>
			) : (
				<SearchSVG className={styles_input.icon_right} />
			)}
			<div className={styles_input.border} />
			{!value && <span className={styles_input.placeholder}>{placeholder}</span>}
		</div>
	);
};
