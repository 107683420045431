import { useEffect, useState } from 'react';
import { useLazyGetUserGetQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { UserResponse } from 'src/app/redux/queries/vacation-service/types/resTypes';
import { VacationType } from 'src/app/redux/queries/vacation-service/types/types';
import { useGetGroupsByGroupIdQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { getDaysSum } from 'src/shared/lib/date';
import { createFullName } from 'src/shared/lib/string';
import { SubGroupType } from '../../GroupPage/types';
import { VACATION_MAP, VacationKeys } from '../consts/consts';
import { User } from '../types';

type UserWithSubgroup = UserResponse & { groups?: { type: SubGroupType; name: string }[] };

export const useGetGroupUsers = (groupId: string | null) => {
	// * API
	const {
		data: groupData,
		isFetching: groupFetching,
		refetch,
	} = useGetGroupsByGroupIdQuery(
		{
			groupId: groupId ?? '',
		},
		{ skip: groupId === null },
	);

	const group = groupData?.body;

	const [getUser, { isFetching: userFetching }] = useLazyGetUserGetQuery();

	// * Users
	const [users, setUsers] = useState<User[]>([]);

	useEffect(() => {
		if (groupId) refetch();
	}, [groupId]);

	useEffect(() => {
		const subGroupUsers: UserWithSubgroup[] = [];

		(group?.subgroups ?? []).forEach(subGroup => {
			subGroup.users?.forEach(user => {
				const existingUserIndex = subGroupUsers.findIndex(u => u.id === user.id);

				if (existingUserIndex !== -1) {
					subGroupUsers[existingUserIndex].groups?.push({
						name: subGroup.name,
						type: subGroup.type,
					});
				} else {
					subGroupUsers.push({
						...user,
						groups: [
							{
								name: subGroup.name,
								type: subGroup.type,
							},
						],
						vacations: group?.users?.find(groupUser => groupUser.id === user.id)?.vacations ?? [],
					});
				}
			});
		});

		const groupUsers: UserWithSubgroup[] = group?.users?.filter(groupUser => !subGroupUsers.find(subGroupUser => subGroupUser.id === groupUser.id)) ?? [];

		const allUsers = [...groupUsers, ...subGroupUsers];

		const promises: ReturnType<typeof getUser>[] = [];

		if (allUsers.length > 0) {
			allUsers.forEach(user => {
				promises.push(
					getUser({
						userid: user.id,
						includecurrentavatar: false,
						includeavatars: false,
						includecover: false,
						includecommunications: false,
						includecompany: false,
						includedepartment: false,
						includeoffice: false,
						includeposition: false,
						includerole: false,
					}),
				);
			});
		}

		Promise.all(promises)
			.then(res => {
				const tableUsers: User[] = [];

				res.forEach((resItem, index) => {
					const resUser = resItem.data?.body?.user;
					const groupUser = allUsers[index];

					const left = groupUser.body.remainingVacationDays; // Остатки отпуска с прошлых лет.
					const total = (resItem.data?.body?.userAddition?.entitledVacationDays ?? 0) + left; // Сумма отпусков в год. TODO: Возможно лучше показывать ошибку вместо 0
					const used = groupUser.vacations // Использованные дни на фактический отпуск.
						? groupUser.vacations
								.filter(({ vacationBody }) => vacationBody?.type === 'Actual')
								.reduce((acc, value) => {
									const time = value.vacationBody?.time;
									let vacationTime = 0;
									if (time && time.endTime && time.startTime) {
										vacationTime = getDaysSum(new Date(time.startTime), new Date(time.endTime));
									}

									return acc + vacationTime;
								}, 0)
						: 0;

					if (resUser) {
						const user: User = {
							id: resUser.id,
							name: createFullName({
								lastName: resUser.lastName ?? '',
								middleName: resUser.middleName ?? '',
								firstName: resUser.firstName ?? '',
							}),
							category: groupUser.groups?.find(group => group.type === 'Category')?.name || null,
							group: groupUser.groups?.find(group => group.type === 'Group')?.name || null,
							team: groupUser.groups?.find(group => group.type === 'Team')?.name || null,
							days: {
								total,
								left,
								used,
							},
							vacations: groupUser.vacations?.map(({ id, vacationBody }) => ({
								id: id as string,
								title: VACATION_MAP[vacationBody?.type as VacationKeys].name,
								startDate: vacationBody?.time?.startTime as string,
								endDate: vacationBody?.time?.endTime as string,
								type: vacationBody?.type as VacationType,
							})),
						};

						tableUsers.push(user);
					}
				});

				setUsers(tableUsers);
			})
			.catch(error => console.log('error', error));
	}, [group]);

	return {
		refetchGroup: refetch,
		users,
		isLoading: groupFetching || userFetching,
	};
};
