import cn from 'classnames';
import React from 'react';
import { ReactComponent as DownArrowSVG } from 'src/shared/assets/svg_icons/arrows/down_2.svg';
import { ReactComponent as UpArrowSVG } from 'src/shared/assets/svg_icons/arrows/up_2.svg';
import s from './ListColumns.module.scss';

interface Column {
	title: string;
	width: number; // In percent
}

interface Props {
	margins: [number, number]; // [left-margin, right-margin] in rem.
	columns: Column[];

	// для сортировки по ФИО
	isAscendingSort?: boolean;
	handleSortChange?: () => void;
}

export const ListColumns: React.FC<Props> = props => {
	const {
		margins, //
		columns,
		isAscendingSort,
		handleSortChange,
	} = props;

	const totalMarginsWidth = margins[0] + margins[1];
	const totalColumnsWidth = columns.reduce((previousValue, currentValue) => previousValue + currentValue.width, 0);
	const totalWidth = totalMarginsWidth + totalColumnsWidth;

	const marginLeft = (margins[0] / totalWidth) * 100 + '%';
	const marginRight = (margins[1] / totalWidth) * 100 + '%';

	// * Render
	return (
		<div
			className={s.container}
			style={{
				marginLeft,
				marginRight,
			}}
		>
			{columns &&
				columns.map(column => {
					const { width, title } = column;

					return (
						<div
							key={title}
							onClick={title === 'ФИО' ? handleSortChange : undefined}
							className={cn(s.sort_wrapper, { [s.clickable]: title === 'ФИО' })}
							style={{
								flexBasis: (width / totalWidth) * 100 + '%',
							}}
						>
							{title}
							{title === 'ФИО' && (isAscendingSort ? <UpArrowSVG /> : <DownArrowSVG />)}
						</div>
					);
				})}
		</div>
	);
};
