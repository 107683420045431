import { ChangeEvent, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { filter_serviceAPI } from 'src/app/redux/queries/filter-service/filter_serviceAPI';
import { useDebounceEffect } from 'src/pages/project_service_pages/hooks/useDebounceEffect';
import { useFloatingWrapper } from 'src/shared/hooks/useFloatingWrapper';
import { createFullName } from 'src/shared/lib/string';
import { SearchBar } from 'src/shared/ui/_inputs/text_Inputs/SearchBar';
import { OptionsWindow } from 'src/shared/ui/_option_lists/OptionsWindow/OptionsWindow';
import { v4 } from 'uuid';
import { CardWrapper } from '../CardWrapper/CardWrapper';
import s from './UsersSearch.module.scss';

const EN_TO_RU_MAPPING: Record<string, string> = {
	q: 'й',
	w: 'ц',
	e: 'у',
	r: 'к',
	t: 'е',
	y: 'н',
	u: 'г',
	i: 'ш',
	o: 'щ',
	p: 'з',
	a: 'ф',
	s: 'ы',
	d: 'в',
	f: 'а',
	g: 'п',
	h: 'р',
	j: 'о',
	k: 'л',
	l: 'д',
	z: 'я',
	x: 'ч',
	c: 'с',
	v: 'м',
	b: 'и',
	n: 'т',
	m: 'ь',
};

export const UsersSearch: FC = () => {
	// * API
	const [findUsers, { data: dataRu, isLoading: isLoadingRu }] = filter_serviceAPI.useLazyGetUsersQuery();
	const [findUsersEn, { data: dataEn, isLoading: isLoadingEn }] = filter_serviceAPI.useLazyGetUsersQuery();

	const searchResults = [...(dataRu?.body ?? []), ...(dataEn?.body ?? [])];
	const isLoading = isLoadingRu || isLoadingEn;

	// * Routing
	const navigate = useNavigate();

	// * Search
	const [searchValue, setSearchValue] = useState('');
	const [showOptions, setShowOptions] = useState(false);
	const [translatedSearchValue, setTranslatedSearchValue] = useState('');
	const [language, setLanguage] = useState('ru');

	const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		event.stopPropagation();

		if (event.key === 'Enter' && searchValue) {
			setShowOptions(false);
			navigate('/users', { state: { search: searchValue } });
		}
	};

	const translateToRu = (input: string): string => {
		return input
			.split('')
			.map(char => EN_TO_RU_MAPPING[char.toLowerCase()] || char)
			.join('');
	};

	const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const russianLang = /[а-яА-ЯЁё]/;

		setSearchValue(value);

		switch (russianLang.test(value)) {
			case true:
				setLanguage('ru');
				break;
			case false:
				setTranslatedSearchValue(translateToRu(value));
				setLanguage('en');
				break;
			default:
				setLanguage('ru');
		}

		setShowOptions(!!value);
	};

	const clearSearchValue = () => {
		setShowOptions(false);
		setSearchValue('');
	};

	useDebounceEffect(
		() => {
			if (!searchValue && !searchResults && !translatedSearchValue) return;

			let promise: { abort: () => void };
			let promiseEn: { abort: () => void };

			const loadData = () => {
				promise = findUsers({
					skipcount: 0,
					takecount: 100,
					fullNameIncludeSubstring: searchValue,
				});
				if (language === 'en') {
					promiseEn = findUsersEn({
						skipcount: 0,
						takecount: 100,
						fullNameIncludeSubstring: translatedSearchValue,
					});
				}
			};

			loadData();

			return () => {
				promise?.abort();
				promiseEn?.abort();
			};
		},
		300,
		[searchValue, translatedSearchValue],
	);

	const inputId = `input_${v4()}`;

	// * Floating
	const { floatingStyles, refs, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(showOptions, (value: any) => setShowOptions(!value));

	// * Render
	return (
		<CardWrapper title="Сотрудники">
			<div
				className={s.search_bar}
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				<SearchBar
					placeholder="Поиск по ФИО"
					value={searchValue}
					onChange={onSearchValueChange}
					onKeyDown={onEnter}
					active={!!searchValue}
					onActiveClick={clearSearchValue}
					loading={isLoading}
					inputId={inputId}
				/>

				{showOptions && searchResults && (
					<div className={s.search_list}>
						<div
							className={s.options}
							ref={refs.setFloating}
							style={{
								...floatingStyles,
								zIndex: 'var(--z-index-floating)',
							}}
							aria-labelledby={headingId}
							{...getFloatingProps()}
						>
							<OptionsWindow
								keyNames={{
									name: 'name',
									value: 'id',
								}}
								options={searchResults.map(user => ({
									id: user.id,
									name: createFullName({
										firstName: user.firstName || '',
										lastName: user.lastName || '',
										middleName: user.middleName || '',
									}),
								}))}
								onOptionClick={option => {
									navigate(`/users/${option.id}`);
								}}
								inputId={inputId}
							/>
						</div>
					</div>
				)}
			</div>
		</CardWrapper>
	);
};
