import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetGroupsByGroupIdQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { useAppSelector } from 'src/app/redux/utils';
import { useRights } from 'src/shared/hooks/useRights';

export const useGetGroupUserRights = () => {
	// * Routing
	const [searchParams] = useSearchParams();
	const groupId = searchParams.get('groupId');
	const year = searchParams.get('year');

	const [hasExtendedRights, setHasExtendedRights] = useState(false);

	// * API
	const { data: groupData, isFetching } = useGetGroupsByGroupIdQuery(
		{
			groupId: groupId ?? '',
		},
		{ skip: groupId === null },
	);

	const departmentId = groupData?.body?.departmentId ?? null;
	const isPlanConfirmed = useMemo(() => {
		return !!groupData?.body?.confirmations?.some(conf => conf.groupId === groupId && conf.year === Number(year));
	}, [groupData, groupId, year]);

	// * Rights
	const isAdmin = useRights();
	const hasAddEditRemoveTimeRights = useRights(7);

	const activeUser = useAppSelector(state => state.user_service.user.userInfo);

	useEffect(() => {
		const isDepartmentManager = activeUser?.departmentUser?.role === 'Manager' && activeUser?.departmentUser?.department?.id === departmentId;
		setHasExtendedRights(isAdmin || hasAddEditRemoveTimeRights || isDepartmentManager);
	}, [departmentId]);

	return {
		hasExtendedRights,
		activeUserId: activeUser?.user?.id,
		isPlanConfirmed,
		isDepartment: !!departmentId, // только в департаментах можно утверждать планы
		isLoading: isFetching,
	};
};
