import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { company_serviceAPI } from 'src/app/redux/queries/company-service/company_serviceAPI';
import { department_serviceAPI } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { rights_serviceAPI } from 'src/app/redux/queries/rights-service/rights_serviceAPI';
import { user_serviceAPI } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { useRights } from 'src/shared/hooks/useRights';
import { Heading } from 'src/shared/ui/Heading';
import { CheckboxField } from 'src/shared/ui/_fields/CheckboxField';
import { DateField } from 'src/shared/ui/_fields/DateField';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { TimeRangeField } from 'src/shared/ui/_fields/TimeRangeField';
import s from './Form.module.scss';
import { defaultValuesUserForm } from './consts/defaultValues';
import { RATES, contractsByTerm, visibilityBirthday } from './consts/options';
import { getSchema } from './consts/schema';
import { useCreateUser } from './hooks/useCreateUser';
import { useEditUser } from './hooks/useEditUser';
import { DefaultValues } from './types';

interface Props {
	userInfo?: DefaultValues;
	isPersonalCard?: boolean;
	onCloseModal: () => void;
}

export const Form: FC<Props> = props => {
	const { userInfo, isPersonalCard = false, onCloseModal } = props;

	const isEdit = !!userInfo;

	// * Rights
	const isAdmin = useRights();
	const hasEditUsersRights = useRights(1);
	const hasEditCompanyDataRights = useRights(11);
	const hasEditPositionRights = useRights(6);
	const hasEditRoleRights = useRights(12);

	// * Router
	const params = useParams<{ userId: string }>();
	const userId: string = params.userId as string;

	// * Form
	const defaultValues = userInfo ? userInfo : defaultValuesUserForm;

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(getSchema(isEdit)),
	});

	const { watch, handleSubmit, setValue } = formMethods;
	const isProbation = watch('probation');
	const contractByTerm = watch('contractByTerm');

	useEffect(() => {
		if (!isProbation) {
			setValue('dateEndProbation', null);
		}
	}, [isProbation]);

	useEffect(() => {
		if (contractByTerm.id === 'Perpetual') {
			setValue('dateEndContract', null);
		}
	}, [contractByTerm.id]);

	// * API
	const { data: positionData } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: false,
	});

	const { data: rightsData } = rights_serviceAPI.useFindRolesQuery({
		skipCount: 0,
		takeCount: 1000,
		locale: 'ru',
	});

	const { data: contractsData } = company_serviceAPI.useFindContractSubjectsQuery({
		skipCount: 0,
		takeCount: 500,
	});

	const { data: departmentData } = department_serviceAPI.useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const { data: genderData } = user_serviceAPI.useGetGenderFindQuery({
		skipcount: 0,
		takecount: 100,
	});

	// * Options
	// - Position
	const positions = positionData?.body?.map(item => ({
		id: item.id,
		name: item.name,
	})) || [{ id: '', name: '' }];

	// - Role
	const roles = rightsData?.body
		? rightsData.body.map(item => ({
				id: item.localizations[0].roleId,
				name: item.localizations[0].name,
			}))
		: [{ id: '', name: '' }];

	// - Contract
	const contracts = contractsData?.body
		? contractsData?.body.map(item => ({
				id: item.id,
				name: item.name,
			}))
		: [{ id: '', name: '' }];

	// - Department
	const departments = departmentData?.body?.map(item => ({
		id: item.id,
		name: item.shortName,
		fullName: item.name,
	})) || [{ id: '', name: '', fullName: '' }];

	// - Gender
	const gender = genderData?.body || [{ id: '', name: '' }];

	// * Change string
	const toChangeFirstLetter = (value: string | number | null) => {
		if (!value) return value;
		return value.toString().charAt(0).toLocaleUpperCase() + value.toString().slice(1);
	};

	// * Submit
	const { onCreateSubmit, isCreateLoading } = useCreateUser(onCloseModal);
	const { onEditSubmit, isEditLoading } = useEditUser(userId, defaultValues, onCloseModal);

	// * Render
	return (
		<form onSubmit={handleSubmit(isEdit ? onEditSubmit : onCreateSubmit)}>
			<FormProvider {...formMethods}>
				<div className={s.container}>
					<Heading
						level={2}
						marginBottom="m"
					>
						{isEdit ? 'Основная информация' : 'Новый сотрудник'}
					</Heading>

					<div className={s.wrapper}>
						{!isEdit && (
							<div className={s.fields}>
								<h5>Аутентификация</h5>

								<StringField
									name="email"
									label="E-mail"
									placeholder="Введите E-mail"
									required
								/>
							</div>
						)}

						<div className={s.fields}>
							<h5>Личное</h5>

							<div className={s.personal_info}>
								<StringField
									name="lastName"
									label="Фамилия"
									placeholder="Введите фамилию"
									required
									disabled={!isPersonalCard && !hasEditUsersRights}
									onChangeWrapper={toChangeFirstLetter}
								/>

								<StringField
									name="firstName"
									label="Имя"
									placeholder="Введите имя"
									required
									disabled={!isPersonalCard && !hasEditUsersRights}
									onChangeWrapper={toChangeFirstLetter}
								/>
							</div>

							<div className={s.personal_info}>
								<StringField
									name="middleName"
									label="Отчество"
									placeholder="Введите отчество"
									disabled={!isPersonalCard && !hasEditUsersRights}
									onChangeWrapper={toChangeFirstLetter}
								/>

								<div className={s.personal_info_inner}>
									<DateField
										label="Дата рождения"
										placeholderText="ДД.ММ.ГГ"
										name="birthday"
										disabled={!isPersonalCard && !hasEditUsersRights}
										showYearDropdown
									/>

									<SelectSingleField
										name="visibilityBirthday"
										label="Отображение даты"
										options={visibilityBirthday}
										disabled={!isEdit || (!isPersonalCard && !hasEditUsersRights)}
									/>
								</div>
							</div>

							<div className={s.work_info}>
								{isEdit && (
									<SelectSingleField
										name="gender"
										label="Пол"
										options={gender}
										disabled={!isPersonalCard}
									/>
								)}
							</div>
						</div>

						<div className={s.fields}>
							<h5>Рабочее</h5>

							<div className={s.work_info}>
								{!isEdit && (
									<SelectSingleField
										name="department"
										label="Департамент"
										options={departments}
										keyNames={{ name: 'name', value: 'id', tooltipText: 'fullName' }}
										required
									/>
								)}

								<SelectSingleField
									name="position"
									label="Должность"
									placeholder="Выберите из списка"
									options={positions}
									disabled={!(!isEdit && hasEditUsersRights) && !hasEditPositionRights}
									isNullable
								/>

								<SelectSingleField
									name="role"
									label="Роль"
									options={roles}
									disabled={!(!isEdit && hasEditUsersRights) && !hasEditRoleRights}
									isNullable
								/>

								{isEdit && (
									<TimeRangeField
										firstName="businessHoursFrom"
										secondName="businessHoursTo"
										labels={['Время работы']}
									/>
								)}
							</div>

							<div className={s.work_info}>
								<SelectSingleField
									name="contract"
									label="Тип договора"
									options={contracts}
									disabled={!(!isEdit && hasEditUsersRights) && !hasEditCompanyDataRights}
									required
								/>

								<SelectSingleField
									name="contractByTerm"
									label="Тип договора по сроку"
									options={contractsByTerm}
									disabled={!(!isEdit && hasEditUsersRights) && !hasEditCompanyDataRights}
									required
								/>

								<DateField
									name="dateEndContract"
									label="Дата окончания договора"
									placeholderText="ДД.ММ.ГГ"
									disabled={contractByTerm.id !== 'FixedTerm'}
									showYearDropdown
									isClearable
								/>
							</div>
							<div className={s.work_info_last_row}>
								<SelectSingleField
									name="rate"
									label="Ставка"
									options={RATES}
									disabled={!(!isEdit && hasEditUsersRights) && !hasEditCompanyDataRights}
									required
								/>

								<StringField
									name="entitledVacationDays"
									label="Дни отпуска"
									disabled={!isPersonalCard && !hasEditUsersRights}
								/>

								<DateField
									label="В компании с"
									placeholderText="ДД.ММ.ГГ"
									name="startWorkingDate"
									disabled={!(!isEdit && hasEditUsersRights) && !hasEditCompanyDataRights}
									showYearDropdown
								/>
							</div>
						</div>

						<div className={s.fields}>
							<div className={s.addition_info}>
								<div className={cn(s.fields_checkbox, s.probation)}>
									<CheckboxField
										name="probation"
										disabled={!(!isEdit && hasEditUsersRights) && !hasEditCompanyDataRights}
									/>
									Испытательный срок
								</div>

								<div className={s.dates}>
									<DateField
										name="dateEndProbation"
										label="Дата окончания"
										placeholderText="ДД.ММ.ГГ"
										disabled={!isProbation || (!hasEditCompanyDataRights && !(!isEdit && hasEditUsersRights))}
										showYearDropdown
									/>
								</div>
							</div>
							{isAdmin && (
								<div className={s.fields_checkbox}>
									<CheckboxField
										name="isAdmin"
										disabled={!isAdmin}
									/>
									Выдать права администратора
								</div>
							)}
						</div>
					</div>

					<ButtonPair
						primaryText={isEdit ? 'Сохранить' : 'Добавить'}
						primaryIsLoading={isCreateLoading || isEditLoading}
						secondaryText="Отмена"
						secondaryIsLoading={isCreateLoading || isEditLoading}
						secondaryOnClick={() => onCloseModal()}
					/>
				</div>
			</FormProvider>
		</form>
	);
};
