import { BaseQueryFn, QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/query';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { GetStatFindApiArg } from 'src/app/redux/queries/time-service/types/regTypes';
import { UserStatInfoFindResultResponse } from 'src/app/redux/queries/time-service/types/resTypes';
import { LeaveType } from 'src/app/redux/queries/time-service/types/types';
import { LeaveDeleteModal } from 'src/entities/_modals/LeaveDeleteModal/LeaveDeleteModal';
import { LeaveEditProps, LeaveEnter } from 'src/pages/TimeTrackingPage/ui/LeaveEnter/LeaveEnter';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg/action/edit.svg';
import { ReactComponent as AlertSVG } from 'src/shared/assets/svg/alerts/info.svg';
import { LEAVE_TIME_OPTIONS, LeaveTypeOption } from 'src/shared/consts/options';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip/Tooltip';
import { Heading } from 'src/shared/ui/Heading';
import s from '../styles/columns.module.scss';

export interface LeaveInfoTableDataItem {
	id: string | null;
	leaveType: LeaveType | null;
	usersHours: {
		current: string | number | null;
		total: string | number | null;
	};
	leaveDates: string;
	startTime: string;
	endTime: string;
	userId: string;
	comment: string | null;
	manager?: string | null;
	subRows?: LeaveInfoTableDataItem[];
}

const columnHelper = createColumnHelper<LeaveInfoTableDataItem>();

export const generateColumnsLeaveInfo = (
	refetch: () => QueryActionCreatorResult<
		QueryDefinition<
			GetStatFindApiArg,
			BaseQueryFn,
			'Import' | 'LeaveTime' | 'Stat' | 'WorkTime' | 'WorkTimeDayJob' | 'WorkTimeMonthLimit',
			UserStatInfoFindResultResponse,
			'timeservice'
		>
	>,
) => [
	columnHelper.accessor('leaveType', {
		header: 'ТИП ОТСУТСТВИЯ',
		cell: info => {
			const leaveType = info.getValue();

			const leaveTypeRU = LEAVE_TIME_OPTIONS.find(option => leaveType === option.id);

			return leaveTypeRU?.name ?? '';
		},
		meta: {
			style: {
				width: '424px',
			},
		},
	}),
	columnHelper.accessor('usersHours', {
		header: 'ВНЕСЕННЫЕ ЧАСЫ  ',
		cell: info => {
			const { current, total } = info.getValue();
			return (
				<>
					<div>
						{current}
						{current !== total && <span className={s.users_hours}>{` (${total})`}</span>}
					</div>
				</>
			);
		},
		meta: {
			style: {
				width: '15%',
			},
		},
	}),
	columnHelper.accessor('leaveDates', {
		header: 'ДАТЫ ОТСУТСТВИЯ',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '15%',
			},
		},
	}),
	columnHelper.accessor('comment', {
		header: 'КОММЕНТАРИЙ',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '30%',
			},
		},
	}),
	columnHelper.accessor('id', {
		header: () => null,
		footer: props => props.column.id,
		cell: ({ row }) => {
			const { comment, leaveType, id: leaveTimeId, startTime, endTime, userId } = row.original;

			const [leaveEditModalInfo, setLeaveEditModalInfo] = useState<Required<Omit<LeaveEditProps, 'onCancel'>> | null>(null);
			const closeLeaveEditModal = () => setLeaveEditModalInfo(null);

			const [leaveDeleteModal, setLeaveDeleteModal] = useState<string | null>(null); // Хранит leaveTimeId если не null.
			const closeLeaveDeleteModal = () => setLeaveDeleteModal(null);

			return (
				<>
					<ModalNewProto
						isOpen={!!leaveEditModalInfo}
						onClose={closeLeaveEditModal}
					>
						{leaveEditModalInfo && (
							<div className={s.leaveModal}>
								<Heading
									level={2}
									marginBottom="l"
								>
									Редактирование часов
								</Heading>

								<LeaveEnter
									userId={userId}
									edit={{
										...leaveEditModalInfo,
										onCancel: closeLeaveEditModal,
									}}
									onSuccess={() => refetch()}
								/>
							</div>
						)}
					</ModalNewProto>

					<LeaveDeleteModal
						leaveDeleteModal={leaveDeleteModal}
						closeLeaveDeleteModal={() => {
							closeLeaveDeleteModal();
							refetch();
						}}
					/>

					{leaveTimeId && (
						<div className={s.icon_wrapper}>
							{row.original.manager && (
								<Tooltip content={`Отредактировано: ${row.original.manager}`}>
									<IconButton Icon={<AlertSVG />} />
								</Tooltip>
							)}
							<IconButton
								Icon={
									<EditSVG
										onClick={() =>
											setLeaveEditModalInfo({
												leaveTimeId: leaveTimeId,
												initialStartTime: startTime,
												initialEndTime: endTime,
												initialLeaveType: LEAVE_TIME_OPTIONS.find(option => option.id === leaveType) as LeaveTypeOption,
												comment: comment ?? null,
											})
										}
									/>
								}
							/>
							<IconButton Icon={<DeleteSVG onClick={() => setLeaveDeleteModal(leaveTimeId)} />} />
						</div>
					)}
				</>
			);
		},
		meta: {
			style: {
				width: '10%',
			},
		},
	}),
];
