import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/redux/utils';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { Modal } from 'src/shared/ui/_modals/Modal';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { Heading } from 'src/shared/ui/Heading';
import { actionsBugReportModal } from '../../slice';
import s from './BugReportModal.module.scss';
import { CreateBugReport } from './CreateBugReport/CreateBugReport';
import { SuccessContent } from './SuccessContent/SuccessContent';

interface Props {
	showModal: boolean;
	setShowModal: (show: boolean) => void;
}

export const BugReportModal: React.FC<Props> = props => {
	const {
		showModal, //
		setShowModal,
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { setCollapsed, resetForm } = actionsBugReportModal;

	// * Selectors
	const collapsed = useAppSelector(state => state.bugReportModal.collapsed);
	const comment = useAppSelector(state => state.bugReportModal.comment);
	const images = useAppSelector(state => state.bugReportModal.media);

	// * Modal
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const closeModal = () => {
		setShowModal(!showModal);
		dispatch(resetForm());
	};

	const toggleSuccessModal = () => {
		showModal && closeModal();
		setShowSuccessModal(prevState => !prevState);
	};

	// * Confirm closing modal
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const toggleConfirmModal = () => setShowConfirmModal(prevState => !prevState);

	const isEmptyForm = useMemo(() => !comment && images.length === 0, [comment, images.length]);

	const onConfirmClose = () => {
		if (isEmptyForm || showSuccessModal) {
			closeModal();
		} else {
			toggleConfirmModal();
		}
	};

	return (
		<>
			{showModal && (
				<ModalNewProto
					isOpen={showModal}
					onClose={onConfirmClose}
					width="xl"
					collapsibleTitle="Обратная связь"
					isCollapsedControlled={collapsed}
					setIsCollapsedControlled={collapsed => dispatch(setCollapsed(collapsed))}
					disableCloseOverlay={!isEmptyForm}
					className={s.bug_report_modal}
				>
					<CreateBugReport
						closeModal={onConfirmClose}
						toggleSuccessModal={toggleSuccessModal}
					/>
				</ModalNewProto>
			)}

			{showConfirmModal && (
				<ModalNewProto
					isOpen={showConfirmModal}
					onClose={toggleConfirmModal}
					width="s"
				>
					<div className={s.confirm_modal}>
						<Heading level={3}>Вы уверены?</Heading>

						<p>Закрыть окно? Ваши данные не сохранятся</p>

						<ButtonPair
							primaryText="Да, закрыть"
							primaryIsLoading={false}
							primaryOnClick={() => {
								toggleConfirmModal();
								closeModal();
							}}
							secondaryText="Отменить"
							secondaryOnClick={toggleConfirmModal}
							secondaryIsLoading={false}
						/>
					</div>
				</ModalNewProto>
			)}

			{showSuccessModal && (
				<Modal
					show={showSuccessModal}
					toggleModal={toggleSuccessModal}
					width={480}
					Content={<SuccessContent toggleSuccessModal={toggleSuccessModal} />}
				/>
			)}
		</>
	);
};
