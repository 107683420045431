import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { department_serviceAPI } from 'src/app/redux/queries/department-service/department_serviceAPI';
import { useGetOfficeFindQuery } from 'src/app/redux/queries/office-service/office_serviceAPI';
import { position_serviceAPI } from 'src/app/redux/queries/position-service/position_serviceAPI';
import { project_serviceAPI } from 'src/app/redux/queries/project-service/project_serviceAPI';
import { rights_serviceAPI } from 'src/app/redux/queries/rights-service/rights_serviceAPI';
import { useDebounceEffect } from 'src/pages/project_service_pages/hooks/useDebounceEffect';
import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import { useRights } from 'src/shared/hooks/useRights';
import { Button } from 'src/shared/ui/_buttons/Button';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import { SelectSingleWithSearch } from 'src/shared/ui/_inputs/selects/SelectSingleWithSearch';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import { useGetUsersContext } from '../../context/useGetUsersContext';
import { RATES } from '../Form/consts/options';
import s from './UsersFilters.module.scss';
import { DEFAULT_OPTION } from './consts/filters';

interface Props {
	isActive: boolean;
	isPending: boolean;
}

export const UsersFilters: React.FC<Props> = props => {
	const {
		isActive, //
		isPending,
	} = props;

	// * Context
	const { getUserFilter } = useGetUsersContext();

	// * Routing
	const searchValue: string = useLocation().state?.search || '';

	// * Rights
	const hasEditUsersRights = useRights(1);

	// * API
	const { data: departmentData } = department_serviceAPI.useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const { data: positionData } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: false,
	});

	const { data: rightsData } = rights_serviceAPI.useFindRolesQuery({
		skipCount: 0,
		takeCount: 1000,
		locale: 'ru',
	});

	const { data: officesData } = useGetOfficeFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const { data: projectsData } = project_serviceAPI.useFindProjectsQuery({
		skipCount: 0,
		takeCount: 1000,
		includedepartment: false,
	});

	// * Filters
	// - Name
	const [fullName, setFullName] = useState(searchValue);

	// - Department
	const departments = departmentData?.body?.map(item => ({
		id: item.id,
		name: item.shortName,
		fullName: item.name,
	})) || [DEFAULT_OPTION];

	const [department, setDepartment] = useState(DEFAULT_OPTION);

	// - Projects
	const projects = projectsData?.body?.map(item => ({
		id: item.id,
		name: item.name,
	})) || [DEFAULT_OPTION];

	const [project, setProject] = useState(DEFAULT_OPTION);

	// - Position
	const positions = positionData?.body?.map(item => ({
		id: item.id,
		name: item.name,
	})) || [DEFAULT_OPTION];

	const [position, setPosition] = useState(DEFAULT_OPTION);

	// - Rate
	const [rate, setRate] = useState(DEFAULT_OPTION);

	// - Office
	const offices = officesData?.body?.map(item => ({
		id: item.id,
		name: `${item.city}, ${item.address}`,
	})) || [DEFAULT_OPTION];

	const [office, setOffice] = useState(DEFAULT_OPTION);

	// console.log('office :>> ', office);

	// - Role
	const roles = rightsData?.body
		? rightsData.body.map(item => ({
				id: item.localizations[0].roleId,
				name: item.localizations[0].name,
			}))
		: [DEFAULT_OPTION];

	const [role, setRole] = useState(DEFAULT_OPTION);

	// * Filter
	useDebounceEffect(
		() => {
			const prepared = {};

			if (fullName) Object.assign(prepared, { fullNameIncludeSubstring: fullName });
			if (department.id) Object.assign(prepared, { departmentsIds: [department.id] });
			if (project.id) Object.assign(prepared, { projectsIds: [project.id] });
			if (position.id) Object.assign(prepared, { positionsIds: [position.id] });
			if (rate.id) Object.assign(prepared, { rate: +rate.id });
			if (role.id) Object.assign(prepared, { rolesIds: [role.id] });
			if (office.id) Object.assign(prepared, { officesIds: [office.id] });

			getUserFilter(
				{
					skipcount: 0,
					takecount: 1000,
					isActive,
					isPending,
					includeCommunications: true,
					includeDepartment: true,
					includeOffice: true,
					includePosition: true,
					includeProject: true, //? [Nst] Нужно ли?
					isAscendingSort: true,
					...prepared,
				},
				true, // Prefer cached values.
			);
		},
		200,
		[fullName, department.id, project.id, position.id, rate.id, role.id, office.id],
	);

	const isEmpty = useMemo(() => {
		return !fullName && !department.id && !position.id && !role.id && !office.id && !project.id && !rate.id;
	}, [fullName, department.id, project.id, position.id, rate.id, role.id, office.id]);

	// * Reset
	const onReset = () => {
		setFullName('');
		setDepartment(DEFAULT_OPTION);
		setProject(DEFAULT_OPTION);
		setPosition(DEFAULT_OPTION);
		setRate(DEFAULT_OPTION);
		setRole(DEFAULT_OPTION);
		setOffice(DEFAULT_OPTION);
	};

	// * Render
	return (
		<div className={s.container}>
			<TextInput
				value={fullName}
				onChange={e => setFullName(e.currentTarget.value)}
				placeholder="Поиск по ФИО"
				RightIcon={<SearchSVG />}
			/>

			<div className={s.filters_select}>
				<SelectSingleWithSearch
					keyNames={{ name: 'name', value: 'id', tooltipText: 'fullName' }}
					options={departments}
					selectedOption={department}
					setSelectedOption={option => setDepartment(option)}
					placeholder="Департамент"
				/>

				<SelectSingleWithSearch
					keyNames={{ name: 'name', value: 'id' }}
					options={projects}
					selectedOption={project}
					setSelectedOption={option => setProject(option)}
					placeholder="Проекты"
				/>

				<SelectSingleWithSearch
					keyNames={{ name: 'name', value: 'id' }}
					options={positions}
					selectedOption={position}
					setSelectedOption={option => setPosition(option)}
					placeholder="Должность"
				/>

				{hasEditUsersRights && (
					<SelectSingle
						keyNames={{ name: 'name', value: 'id' }}
						options={RATES}
						selectedOption={rate}
						setSelectedOption={option => setRate(option)}
						placeholder="Ставка"
					/>
				)}

				{hasEditUsersRights && (
					<SelectSingleWithSearch
						keyNames={{ name: 'name', value: 'id' }}
						options={roles}
						selectedOption={role}
						setSelectedOption={option => setRole(option)}
						placeholder="Роль"
					/>
				)}

				<SelectSingleWithSearch
					keyNames={{ name: 'name', value: 'id' }}
					options={offices}
					selectedOption={office}
					setSelectedOption={option => setOffice(option)}
					placeholder="Офис"
				/>

				<Button
					variant="secondary"
					disabled={isEmpty}
					onClick={onReset}
				>
					Сбросить
				</Button>
			</div>
		</div>
	);
};
