import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React, { ComponentPropsWithoutRef, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetLeaveTimeFindQuery, usePatchLeaveTimeEditMutation, usePostLeaveTimeCreateMutation } from 'src/app/redux/queries/time-service/time_serviceAPI';
import { useGetUserGetQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { useFindWorkTimeMonthLimits } from 'src/pages/TimeTrackingPage/api/useWorkTimeMonthLimits/useFindWorkTimeMonthLimits';
import { COMMENT_LIMIT } from 'src/pages/TimeTrackingPage/consts/consts';
import { getHours } from 'src/pages/TimeTrackingPage/ui/LeaveEnter/lib/getHours';
import { TimeSelected } from 'src/pages/TimeTrackingPage/ui/TimeSelected/TimeSelected';
import { LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO } from 'src/shared/consts/consts';
import { LEAVE_TIME_OPTIONS, LeaveTypeOption } from 'src/shared/consts/options';
import { filterHolidaysByBinaryString, generateDatesByIsoInterval, getCurrentDayNumber } from 'src/shared/lib/date';
import { Button } from 'src/shared/ui/_buttons/Button';
import { DateRangeField } from 'src/shared/ui/_fields/DateRangeField';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import * as yup from 'yup';
import s from './LeaveEnter.module.scss';
import { useAppDispatch } from 'src/app/redux/utils';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';

const SCHEMA = yup.object().shape({
	startTime: yup.date().required('Отсутствует начало диапазона').nullable(),
	endTime: yup.date().required('Отсутствует конец диапазона').nullable(),
	leaveType: yup.object().shape({
		id: yup.string().nullable().required('Поле не может быть пустым'),
	}),
	comment: yup.string().max(COMMENT_LIMIT, `Комментарий не может быть больше ${COMMENT_LIMIT}`).nullable(),
});

interface FieldValues {
	startTime: Date | null;
	endTime: Date | null;
	leaveType: LeaveTypeOption | { name: null; id: null };
	comment: string | null;
}

export interface LeaveEditProps {
	leaveTimeId?: string;
	initialStartTime: string;
	initialEndTime: string;
	initialLeaveType: LeaveTypeOption;
	comment: string | null;

	onCancel: () => void; // Для закрытия модального окна.
}

interface Props extends ComponentPropsWithoutRef<'form'> {
	userId: string;
	edit?: LeaveEditProps;
	onSuccess?: () => void; // Для закрытия модального окна.
}

export const LeaveEnter: React.FC<Props> = props => {
	const {
		userId, //
		edit,
		onSuccess,
	} = props;

	const editMode = !!edit; // Режим редактирования часов для админа. Дата и проект задаются через пропсы. Менять нельзя.
	const controlledMode = !edit?.leaveTimeId; // Режим редактирования часов для админа/менеджера, часы и описание задаются через пропсы. Менять можно и нужно.

	const TODAY = new Date();
	const thisMonthISO = DateTime.fromJSDate(TODAY).minus({ month: 1 }).startOf('month').toISO();
	const nextMonthISO = DateTime.fromJSDate(TODAY).plus({ month: 1 }).endOf('month').toISO();

	// * API
	const { workTimeMonthLimits, isLoading: workTimeMonthLimitsLoading } = useFindWorkTimeMonthLimits(TODAY, userId, controlledMode);
	const [createLeaveTime, { isLoading: createLeaveTimeLoading }] = usePostLeaveTimeCreateMutation();
	const [editLeaveTime, { isLoading: editLeaveTimeLoading }] = usePatchLeaveTimeEditMutation();
	const { currentData: leaveTimeResponse, isLoading: isFindLeaveTimeLoading } = useGetLeaveTimeFindQuery({
		userid: userId,
		starttime: thisMonthISO,
		endtime: nextMonthISO,
		takecount: 1000,
		skipcount: 0,
	});

	const { currentData: userData } = useGetUserGetQuery({ userid: userId, includecompany: true });

	const rate = userData?.body?.companyUser?.rate;

	const isInitializing = workTimeMonthLimitsLoading || isFindLeaveTimeLoading;
	const isUpdating = createLeaveTimeLoading || editLeaveTimeLoading;

	const workTimeMonthLimitsExist = workTimeMonthLimits && workTimeMonthLimits.length > 0;

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	// * Generate excludeDates
	const excludeDates = leaveTimeResponse?.body
		?.filter(({ leaveTime }) => (leaveTime.managerLeaveTime ? leaveTime.managerLeaveTime?.isActive : leaveTime.isActive)) // удаляем отсутствия, удаленные менеджером
		.map(({ leaveTime }) => {
			const { managerLeaveTime } = leaveTime;
			const actualLeaveTime = managerLeaveTime ? managerLeaveTime : leaveTime;
			return generateDatesByIsoInterval(actualLeaveTime.startTime, actualLeaveTime.endTime);
		})
		.flat();

	// * Form
	const defaultValues: FieldValues = {
		startTime: edit?.initialStartTime ? new Date(edit.initialStartTime) : null,
		endTime: edit?.initialEndTime ? new Date(edit.initialEndTime) : null,
		leaveType: edit?.initialLeaveType ?? {
			name: null,
			id: null,
		},
		comment: edit?.comment ?? null,
	};

	const formMethods = useForm<FieldValues>({
		defaultValues,
		resolver: yupResolver(SCHEMA),
	});

	const { handleSubmit, watch, setValue, formState } = formMethods;

	const leaveType = watch('leaveType');
	const startTime = watch('startTime');
	const endTime = watch('endTime');

	const decreeSelected = leaveType.id === 'Decree';

	const formErrors = formState.errors;
	const formNotValid = Boolean(Object.values(formErrors).length > 0) || !endTime;

	// * Minutes
	const hours = getHours(startTime, endTime, workTimeMonthLimits) * (rate ?? 1);
	const minutes = hours * 60;

	// * Time select
	const currentDayNumber = getCurrentDayNumber(TODAY);
	const allowedToEditPreviousMonth = currentDayNumber <= LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO;

	const firstMonthLimit = workTimeMonthLimitsExist && workTimeMonthLimits[0];
	const lastMonthLimit = workTimeMonthLimitsExist && workTimeMonthLimits[workTimeMonthLimits.length - 1];
	const previousMonthLimit = workTimeMonthLimitsExist && workTimeMonthLimits[1];

	const highlightedDates = filterHolidaysByBinaryString(workTimeMonthLimits);

	// ! Здесь ненужно задавать временную зону Москвы так как datePicker работает по локальному времени...
	const minDate = allowedToEditPreviousMonth
		? previousMonthLimit &&
			DateTime.fromObject({
				month: previousMonthLimit.month,
				year: previousMonthLimit.year,
			})
				.startOf('month')
				.toJSDate()
		: firstMonthLimit &&
			DateTime.fromObject({
				month: firstMonthLimit.month,
				year: firstMonthLimit.year,
			})
				.startOf('month')
				.toJSDate();

	const maxDate =
		lastMonthLimit &&
		DateTime.fromObject({
			month: lastMonthLimit.month,
			year: lastMonthLimit.year,
		})
			.endOf('month')
			.toJSDate();
	// !...

	// * Auto set decree
	useEffect(() => {
		if (decreeSelected) {
			setValue('startTime', TODAY);
			setValue('endTime', DateTime.fromJSDate(TODAY).plus({ year: 3 }).toJSDate());
		} else if (!editMode) {
			setValue('startTime', null);
			setValue('endTime', null);
		}
	}, [decreeSelected]);

	useEffect(() => {
		if (decreeSelected && startTime) {
			setValue('endTime', DateTime.fromJSDate(startTime).plus({ year: 3 }).toJSDate());
		}
	}, [startTime, decreeSelected]);

	// * Submit
	const onSubmit = (data: FieldValues) => {
		const startTime = data.startTime && DateTime.fromJSDate(data.startTime).toISO({ includeOffset: false });
		const endTime = data.endTime && DateTime.fromJSDate(data.endTime).toISO({ includeOffset: false });
		const leaveType = data.leaveType.id;
		const comment = data.comment;

		if (editMode) {
			editLeaveTime({
				leaveTimeId: edit?.leaveTimeId,
				body: [
					{
						op: 'replace',
						path: '/Minutes',
						value: minutes,
					},
					{
						op: 'replace',
						path: '/StartTime',
						value: startTime,
					},
					{
						op: 'replace',
						path: '/EndTime',
						value: endTime,
					},
					{
						op: 'replace',
						path: '/Comment',
						value: comment,
					},
				],
			})
				.unwrap()
				.then(() => {
					edit?.onCancel();
					dispatch(
						addNotification({
							type: 'success',
							message: 'Отсутствие успешно отредактировано.',
						}),
					);
					onSuccess && onSuccess();
				})
				.catch(e => console.log(e));
		} else if (startTime && endTime && leaveType) {
			createLeaveTime({
				createLeaveTimeRequest: {
					userId,
					startTime,
					endTime,
					minutes,
					leaveType,
					comment,
				},
			})
				.unwrap()
				.then(() => {
					dispatch(
						addNotification({
							type: 'success',
							message: 'Отсутствие успешно внесено.',
						}),
					);
					onSuccess && onSuccess();
				})
				.catch(e => console.log(e));
		}
	};

	// * Render
	return (
		<div>
			{!isInitializing && workTimeMonthLimitsExist && minDate && maxDate ? (
				<FormProvider {...formMethods}>
					<form
						className={s.container}
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className={s.fields}>
							<SelectSingleField
								name="leaveType"
								label="Тип отсутствия"
								placeholder="Выберите из списка"
								options={LEAVE_TIME_OPTIONS}
								disabled={editMode}
							/>

							<div className={s.timeFields}>
								<DateRangeField
									disabledEnd={decreeSelected}
									labels={['Диапазон отсутствия']}
									startTimeName="startTime"
									endTimeName="endTime"
									minDate={minDate}
									maxDate={maxDate}
									excludeDates={editMode ? [] : excludeDates}
									isClearable
									highlightedDates={highlightedDates}
								/>

								{!decreeSelected && <TimeSelected hours={hours} />}
							</div>

							<TextAreaField
								name="comment"
								label="Комментарий"
								placeholder="Введите комментарий"
								characterLimit={COMMENT_LIMIT}
							/>
						</div>

						<div className={s.buttons}>
							{!!edit?.onCancel && (
								<Button
									type="button"
									isLoading={isUpdating}
									onClick={edit.onCancel}
									variant="tertiary"
								>
									Отмена
								</Button>
							)}

							<Button
								type="submit"
								isLoading={isUpdating}
								disabled={formNotValid}
							>
								Сохранить часы
							</Button>
						</div>
					</form>
				</FormProvider>
			) : (
				<LoaderCircle />
			)}
		</div>
	);
};
