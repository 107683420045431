import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React, { ComponentPropsWithoutRef, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyGetWorkTimeFindQuery, usePatchWorkTimeEditMutation, usePostWorkTimeCreateMutation } from 'src/app/redux/queries/time-service/time_serviceAPI';
import { useAppDispatch } from 'src/app/redux/utils';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { COMMENT_LIMIT, OTHER_PROJECT_NAME } from 'src/pages/TimeTrackingPage/consts/consts';
import { EMPTY_ID, LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO } from 'src/shared/consts/consts';
import { getCurrentDayNumber, getCurrentYearNumber, getMonthNumbers } from 'src/shared/lib/date';
import { formatToUpperFirstLetter } from 'src/shared/lib/string';
import { ConditionalRendering } from 'src/shared/providers';
import { Button } from 'src/shared/ui/_buttons/Button';
import { NumberField } from 'src/shared/ui/_fields/NumberField';
import { SelectSingleField } from 'src/shared/ui/_fields/SelectSingleField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import * as yup from 'yup';
import { setDescriptionAndHours } from '../lib/setDescriptionAndHours';
import { FormValues, ProjectInfo } from '../types';
import s from './TimeEnter.module.scss';

export interface TimeEditProps {
	initialProject: ProjectInfo; // если есть, то невозможно выбирать проект.
	initialDate: Date; // если есть, то невозможно выбирать дату.
	onCancel: () => void; // Для закрытия модального окна.
	hours?: number | null;
	description?: string | null;
	workTimeId?: string | null;
}

interface Props extends ComponentPropsWithoutRef<'form'> {
	userId: string;
	initialSelectedDate?: Date; // изначально выбранный месяц
	setSelectedDate?: (date: Date) => void;
	edit?: TimeEditProps;
}

export const TimeEnter: React.FC<Props> = props => {
	const {
		userId, //
		initialSelectedDate,
		setSelectedDate,
		edit,
	} = props;

	const editMode = !!edit; // Режим редактирования часов для админа. Дата и проект задаются через пропсы. Менять нельзя.
	const controlledMode = Boolean(editMode && (edit.hours !== undefined || edit.description !== undefined || edit.workTimeId !== undefined)); // Режим редактирования часов для админа/менеджера, часы и описание задаются через пропсы. Менять можно и нужно.

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	// * API
	const [editWorkTime, { isLoading: editWorkTimeLoading }] = usePatchWorkTimeEditMutation();
	const [createWorkTime, { isLoading: createWorkTimeLoading }] = usePostWorkTimeCreateMutation();
	const [getWorkTime, { currentData: workTimesRes, isLoading: workTimesLoading }] = useLazyGetWorkTimeFindQuery();

	const isUpdating = createWorkTimeLoading || editWorkTimeLoading;

	const createMonthName = (date: Date) => {
		const luxonDate = DateTime.fromJSDate(date);
		return `${formatToUpperFirstLetter(luxonDate.toLocaleString({ month: 'long' }, { locale: 'ru-RU' }))} ${luxonDate.year}`;
	};

	// * Form
	const defaultValues: FormValues = {
		selectedDate: {
			name: createMonthName(edit?.initialDate ?? initialSelectedDate ?? new Date()),
			id: edit?.initialDate ?? initialSelectedDate ?? new Date(),
		},
		selectedProject: edit?.initialProject ?? {
			name: null,
			id: null,
		},
		hours: edit?.hours ?? null,
		description: edit?.description ?? null, // Необходим только для проекта 'Другое'
	};

	const schema = yup.object().shape({
		selectedProject: yup.object().shape({
			id: yup.string().nullable().required('Выберите проект'),
		}),
		hours: yup.number().nullable().min(0, 'минимальное значение: 0').max(744, 'максимальное значение: 744'),
		description: yup
			.string()
			.nullable()
			.notRequired()
			.when('selectedProject.id', {
				is: EMPTY_ID,
				then: yup.string().max(COMMENT_LIMIT, `Комментарий не может быть больше ${COMMENT_LIMIT}`).nullable().required('Описание обязательно'),
			}),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, formState, clearErrors, setValue } = formMethods;

	const selectedProject = watch('selectedProject');
	const selectedDate = watch('selectedDate');

	const workTimesInfo = workTimesRes?.body ?? [];

	useEffect(() => {
		if (!controlledMode) {
			setDescriptionAndHours(setValue, selectedProject, workTimesInfo);

			if (selectedProject.id !== EMPTY_ID) {
				clearErrors('description');
			}
		}
	}, [selectedProject]);

	useEffect(() => {
		setSelectedDate && setSelectedDate(selectedDate.id);

		if (!controlledMode) {
			const { currentMonth: month } = getMonthNumbers(selectedDate.id);
			const year = getCurrentYearNumber(selectedDate.id);

			getWorkTime(
				{
					userid: userId,
					year,
					month,
					takecount: 1000, // ! HARDCODE
					skipcount: 0, // ! HARDCODE
				},
				true, // Prefer cached value
			)
				.unwrap()
				.then(res => {
					const workTimesInfo = res?.body ?? [];
					setDescriptionAndHours(setValue, selectedProject, workTimesInfo);
				})
				.catch(error => console.log(error));
		}
	}, [selectedDate.id]);

	const formErrors = formState.errors;
	const formNotValid = Boolean(Object.values(formErrors).length > 0) || !selectedProject.id;

	// * Month select
	const TODAY = new Date();

	const availableMonths = [TODAY];

	const currentDayNumber = getCurrentDayNumber(TODAY);
	if (currentDayNumber <= LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO) {
		const nextMonth = DateTime.fromJSDate(TODAY).minus({ month: 1 }).toJSDate();
		availableMonths.push(nextMonth);
	}
	const monthOptions = availableMonths.map(date => {
		return {
			name: createMonthName(date),
			id: date,
		};
	});

	// * Project select
	const projectOptions = workTimesInfo
		.filter(({ workTime }) => workTime.project.id !== EMPTY_ID) // 'Другое' проект приходит только после того как пользователь создал для него время. Удаляем его из списка для удобства. Проще потом сделать concat, так он всегда будет внизу списка.
		.map(({ workTime }) => ({
			name: workTime.project.name,
			id: workTime.project.id,
		}))
		.concat({
			name: OTHER_PROJECT_NAME,
			id: EMPTY_ID,
		});

	// * Submit
	const onSubmit = (values: FormValues) => {
		const { selectedProject, selectedDate, description, hours } = values;
		const otherExists = !!workTimesInfo.find(workTime => workTime.workTime.project.id === EMPTY_ID);

		const workTimeId = controlledMode ? edit?.workTimeId : workTimesInfo.find(workTime => workTime.workTime.project.id === selectedProject.id)?.workTime.id;

		// если часы проекта ранее не вносились, но запрос идет на редактирование, то отображаем алерт "часы внесены"
		const modifiedByUserAt = workTimesInfo.find(workTime => workTime.workTime.project.id === selectedProject.id)?.workTime.modifiedByUserAtUtc;

		if (otherExists || workTimeId) {
			workTimeId &&
				editWorkTime({
					workTimeId,
					body: [
						{
							op: 'replace',
							path: '/Hours',
							value: hours,
						},
						{
							op: 'replace',
							path: '/Description',
							value: description,
						},
					],
				})
					.unwrap()
					.then(() => {
						edit?.onCancel();
						dispatch(
							addNotification({
								type: 'success',
								message: modifiedByUserAt ? 'Рабочие часы успешно отредактированы.' : 'Рабочие часы успешно внесены.',
							}),
						);
					})
					.catch(error => console.log(error));
		} else {
			const year = getCurrentYearNumber(selectedDate.id);
			const { currentMonth: month } = getMonthNumbers(selectedDate.id);

			hours &&
				createWorkTime({
					createWorkTimeRequest: {
						year,
						month,
						hours,
						description: description ?? '', // ? Не уверен, что description может быть null.
						offset: 0, // ! HARDCODED
					},
				})
					.unwrap()
					.then(() => {
						edit?.onCancel();
						dispatch(
							addNotification({
								type: 'success',
								message: 'Рабочие часы успешно внесены.',
							}),
						);
					})
					.catch(error => console.log(error));
		}
	};

	// * Render
	return (
		<div>
			<ConditionalRendering
				initialLoading={workTimesLoading}
				isSuccessful={monthOptions && monthOptions.length > 0}
				LoadedResult={
					<FormProvider {...formMethods}>
						<form
							className={s.container}
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className={s.fields}>
								<SelectSingleField
									name="selectedProject"
									options={projectOptions}
									label="Проект"
									placeholder="Выберите проект"
									disabled={editMode}
								/>

								<div className={s.fields__row}>
									<SelectSingleField
										name="selectedDate"
										options={monthOptions}
										label="Месяц"
										disabled={editMode}
									/>

									<NumberField
										name="hours"
										numberType="integer"
										label="Количество часов"
										placeholder="0"
										noNegative
									/>
								</div>

								<TextAreaField
									name="description"
									label="Комментарий"
									placeholder="Введите комментарий"
									characterLimit={COMMENT_LIMIT}
								/>
							</div>

							<div className={s.buttons}>
								{!!edit?.onCancel && (
									<Button
										type="button"
										isLoading={isUpdating}
										onClick={edit.onCancel}
										variant="tertiary"
									>
										Отмена
									</Button>
								)}

								<Button
									type="submit"
									isLoading={isUpdating}
									disabled={formNotValid}
								>
									Сохранить часы
								</Button>
							</div>
						</form>
					</FormProvider>
				}
			/>
		</div>
	);
};
