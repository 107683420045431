import { FC } from 'react';
import { PageDecorator } from 'src/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { FormsCard } from '../FormsCard/FormsCard';
import { InfoCards } from '../InfoCards/InfoCards';
import s from './TimeTrackingPage.module.scss';
import { useSelectedDate } from './hooks/useSelectedDate';
import { useSearchParams } from 'react-router-dom';

export const TimeTrackingPage: FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const { selectedDate, setSelectedDate } = useSelectedDate(searchParams, setSearchParams);

	// * Render
	return (
		<PageDecorator>
			<PageTitle title="Учет времени" />

			<div className={s.container}>
				<FormsCard
					selectedDate={selectedDate}
					setSelectedDate={setSelectedDate}
					searchParams={searchParams}
					setSearchParams={setSearchParams}
				/>

				<InfoCards
					selectedDate={selectedDate}
					setSelectedDate={setSelectedDate}
				/>
			</div>
		</PageDecorator>
	);
};
