import { FC, useState } from 'react';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { SelectStringSearchFilter, SelectValue } from 'src/entities/_filters/SelectStringSearchFilter';
import { AddUserList } from 'src/features/_card_lists/AddUserList';
import { UserListItem } from 'src/features/_card_lists/types';
import { matchItems } from 'src/shared/lib/filter';
import { Heading } from 'src/shared/ui/Heading';
import { Status } from '../../consts';
import { MCType } from '../../types';
import s from './AddAddresseeMC.module.scss';

interface Props {
	allUsers: UserListItem[];
	tempSelectedUserIds: string[];
	setTempSelectedUserIds: (selectedUsers: string[]) => void;
	selectedUserIds: string[];
	setSelectedUserIds: (selectedUsers: string[]) => void;
	setActiveMC: React.Dispatch<React.SetStateAction<MCType>>;
}

export const AddAddresseeMC: FC<Props> = props => {
	const {
		allUsers, //
		tempSelectedUserIds,
		setTempSelectedUserIds,
		selectedUserIds,
		setSelectedUserIds,
		setActiveMC,
	} = props;

	// * Filters
	const [statusFilter, setStatusFilter] = useState<SelectValue>({ id: null, name: '' });
	const [userName, setUserName] = useState('');

	const filteredUsersByStatus = statusFilter.id === null ? allUsers : allUsers.filter(user => user.status === statusFilter.id);
	const filteredUsers = matchItems(filteredUsersByStatus, userName);

	const onSaveClick = () => {
		setActiveMC('email');
		setSelectedUserIds(tempSelectedUserIds);
	};

	const onCancelClick = () => {
		setActiveMC('email');
		setTempSelectedUserIds(selectedUserIds);
	};

	// * Render
	return (
		<div className={s.container}>
			<Heading level={2}>Добавить адресата</Heading>

			<SelectStringSearchFilter
				selectPlaceholder="Статус"
				selectValue={statusFilter}
				setSelect={(value: SelectValue) => setStatusFilter(value)}
				selectOptions={[
					{ id: Status.True, name: Status.True },
					{ id: Status.False, name: Status.False },
				]}
				searchPlaceholder="Введите ФИО сотрудника"
				searchStringValue={userName}
				setSearchString={setUserName}
			/>

			<AddUserList
				users={filteredUsers}
				selectedUsersIds={tempSelectedUserIds}
				setSelectedUserIds={setTempSelectedUserIds}
				extraColumns={[
					{
						key: 'status',
						header: 'Статус',
					},
				]}
				hasSelectAll
			/>

			<ButtonPair
				primaryText="Добавить"
				primaryOnClick={onSaveClick}
				primaryIsLoading={false} // TODO
				primaryDisabled={false} // TODO
				secondaryText="Отменить"
				secondaryOnClick={onCancelClick}
				secondaryIsLoading={false} // TODO
			/>
		</div>
	);
};
