import { UserListItem } from 'src/features/_card_lists/types';

export const getPositionOptions = (usersInSubgroup: UserListItem[]) => {
	const positionOptions = usersInSubgroup
		.map(item => ({
			id: item.position || 'Без должности',
			name: item.position || 'Без должности',
		}))
		.filter((value, index, array) => index === array.findIndex(item => item.id === value.id && item.name === value.name));

	return positionOptions;
};
