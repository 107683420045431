import { FC, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'src/app/redux/utils';
import { VACATION_MAP } from 'src/pages/vacation/ui/VacationPage/consts/consts';
import { useVacationPageContext } from 'src/pages/vacation/ui/VacationPage/context/useGroupUsersContext';
import { getOnClickCallback } from 'src/pages/vacation/ui/VacationPage/lib/getOnClickCallback/getOnClickCallback';
import { getSortedVacations } from 'src/pages/vacation/ui/VacationPage/lib/getSortedVacations/getSortedVacations';
import { getVacationOverlaps } from 'src/pages/vacation/ui/VacationPage/lib/getVacationOverlaps/getVacationOverlaps';
import { User } from 'src/pages/vacation/ui/VacationPage/types';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip/Tooltip';
import { CELL_GAP, CELL_SIZE } from '../../../../consts';
import s from './VacationBars.module.scss';

const MONTH_GAP = 0.75;

interface Props {
	user: {
		id: string;
		name: string;
	};
	monthIndex: number;
	selectedYear: {
		year: number;
		months: {
			name: string;
			holidays: string;
		}[];
	};
	users: User[];
	departmentId: string | null;
	projectId: string | null;
	hasExtendedRights: boolean;
	isPlanConfirmed: boolean;
}

export const VacationBars: FC<Props> = props => {
	const {
		user, //
		monthIndex,
		selectedYear,
		users,
		departmentId,
		projectId,
		hasExtendedRights,
		isPlanConfirmed,
	} = props;

	const [searchParams] = useSearchParams();
	const year = searchParams.get('year') as string;

	// * Current user
	const userInfo = useAppSelector(state => state.user_service.user.userInfo);

	// * Context
	const { setActiveUserId } = useVacationPageContext();

	// * Render
	const VacationsToRender: ReactNode[] = [];

	const sortedVacations = getSortedVacations(users, user, monthIndex);
	if (!sortedVacations) return null;

	sortedVacations.forEach(vacation => {
		const overlaps = getVacationOverlaps(vacation, sortedVacations);

		const startDay = vacation.startDateLuxon.day;
		const startMonth = vacation.startDateLuxon.month;

		const endDay = vacation.endDateLuxon.day;
		const endMonth = vacation.endDateLuxon.month;

		const monthDiff = endMonth - startMonth;

		// * Width calculation
		// - Cells calculation
		const monthGapWidth = MONTH_GAP * monthDiff;

		let cellsWidth = 0;

		if (monthDiff === 0) {
			const cellsCount = endDay - startDay + 1;
			cellsWidth = cellsCount * CELL_SIZE + (cellsCount - 1) * CELL_GAP;
		} else {
			const vacationMonths = selectedYear.months.slice(monthIndex, endMonth);
			vacationMonths.forEach((month, index) => {
				if (index === 0) {
					const cellsCount = month.holidays.length - startDay + 1;
					cellsWidth = cellsCount * CELL_SIZE + (cellsCount - 1) * CELL_GAP;
				} else if (index === vacationMonths.length - 1) {
					const cellsCount = endDay;
					cellsWidth = cellsWidth + (cellsCount * CELL_SIZE + (cellsCount - 1) * CELL_GAP);
				} else {
					const cellsCount = month.holidays.length;
					cellsWidth = cellsWidth + (cellsCount * CELL_SIZE + (cellsCount - 1) * CELL_GAP);
				}
			});
		}

		const onBarClick = getOnClickCallback({
			vacationType: vacation.type,
			departmentId,
			projectId,
			isActiveUserVacation: user.id === userInfo?.user.id,
			setActiveUserId,
			month: startMonth,
			year,
			hasExtendedRights,
			isPlanConfirmed,
		});

		// * Render
		VacationsToRender.push(
			<div
				key={vacation.id}
				className={s.container}
				onClick={() => onBarClick && onBarClick(user.id)}
				style={{
					cursor: onBarClick ? 'pointer' : 'default',
					backgroundColor: Object.hasOwn(VACATION_MAP, vacation.type) ? VACATION_MAP[vacation.type as keyof typeof VACATION_MAP].color : 'black',
					left: (startDay - 1) * CELL_SIZE + CELL_GAP * (startDay - 1) + 'rem',
					width: monthGapWidth + cellsWidth + 'rem',
				}}
			>
				<Tooltip
					key={vacation.id}
					type="custom"
					content={
						<div className={s.tooltip}>
							<div>{user.name}</div>
							{overlaps.map(vacation => (
								<div key={vacation.id}>
									<div>{vacation.title}</div>
									<div>{vacation.formattedStartDate === vacation.formattedEndDate ? vacation.formattedStartDate : `${vacation.formattedStartDate} - ${vacation.formattedEndDate}`}</div>
								</div>
							))}
						</div>
					}
				/>
			</div>,
		);
	});

	return <>{VacationsToRender.map(Vacation => Vacation)}</>;
};
