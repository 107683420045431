import cn from 'classnames';
import { FC } from 'react';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip/Tooltip';
import { CollapseArrows } from 'src/shared/ui/CollapseArrows';
import { useVacationPageContext } from '../../../../context/useGroupUsersContext';
import { useGetGroupUserRights } from '../../../../hooks/useGetGroupUserRights';
import { getTableHeight } from '../../../../lib/getTableHeight/getTableHeight';
import { User } from '../../../../types';
import { DIVIDER_WIDTH } from '../../consts';
import { Column, ColumnId, SortBy } from '../../types';
import { VACATION_TOOLTIP_TEXT } from './consts/consts';
import s from './UsersTable.module.scss';

interface Props {
	users: {
		mainUser: User[] | undefined;
		otherUsers: User[];
	};
	columns: Column[];
	sortBy: { ascending: boolean; by: SortBy } | null;
	setSortBy: (sortBy: { ascending: boolean; by: SortBy } | null) => void;
}

export const UsersTable: FC<Props> = props => {
	const {
		users, //
		columns,
		sortBy,
		setSortBy,
	} = props;

	// * Rights
	const { hasExtendedRights, activeUserId, isPlanConfirmed, isDepartment } = useGetGroupUserRights();
	const hasRightsToEdit = (userId: string) => (hasExtendedRights || activeUserId === userId) && !isPlanConfirmed && isDepartment;

	// * Context
	const { setActiveUserId, setActiveLeftDaysModal } = useVacationPageContext();

	const onHeaderClick = (columnId: ColumnId) => {
		if (columnId !== 'days') {
			if (sortBy?.by === columnId) {
				setSortBy({ ...sortBy, ascending: !sortBy.ascending });
			} else {
				setSortBy({ by: columnId, ascending: true });
			}
		}
	};

	// * Render
	return (
		<div className={s.container}>
			{columns.map(column => (
				<div
					key={column.id}
					className={cn(s.column, { [s.activeUser]: !!users?.mainUser })}
					style={{ width: column.width + 'rem' }}
				>
					<div className={s.header}>
						<div className={s.row} />
						<div
							className={cn(s.row, s.row__header, column.id !== 'days' && s.row_clickable)}
							onClick={() => onHeaderClick(column.id)}
						>
							{column.name}
							{column.id !== 'days' && (
								<CollapseArrows
									isOpen={sortBy?.by === column.id && sortBy.ascending ? true : false}
									arrowType="empty"
								/>
							)}
						</div>
					</div>

					{column.id === 'days'
						? Object.values(users).map(userList =>
								userList?.map(user => (
									<div
										key={user.id}
										className={s.row}
									>
										<Tooltip
											content={VACATION_TOOLTIP_TEXT.left}
											type="custom"
										>
											<div
												className={cn(s.cell, s.cell__day, { [s.cell_clickable]: hasRightsToEdit(user.id) })}
												onClick={() => hasRightsToEdit(user.id) && setActiveLeftDaysModal(user.id)}
											>
												{user.days.left}
											</div>
										</Tooltip>

										<Tooltip
											content={VACATION_TOOLTIP_TEXT.total}
											type="custom"
										>
											<div className={cn(s.cell, s.cell__day)}>{user.days.total}</div>
										</Tooltip>

										<Tooltip
											content={VACATION_TOOLTIP_TEXT.used}
											type="custom"
										>
											<div className={cn(s.cell, s.cell__day)}>{user.days.used}</div>
										</Tooltip>
									</div>
								)),
							)
						: Object.values(users).map(userList =>
								userList?.map(user => (
									<div
										key={user.id}
										className={cn(s.cell, {
											[s.cell_clickable]: column.id === 'name' && hasRightsToEdit(user.id),
											[s.cell__user]: column.id === 'name',
										})}
										onClick={() => column.id === 'name' && hasRightsToEdit(user.id) && setActiveUserId(user.id)}
									>
										{/*  @ts-ignore */}
										{user[column.id]}
									</div>
								)),
							)}
				</div>
			))}

			<div
				className={s.divider}
				style={{
					height: getTableHeight(users),
					width: DIVIDER_WIDTH + 'rem',
				}}
			/>
		</div>
	);
};
