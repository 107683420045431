import { useGetUserGetQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';

export const useGetRate = (userId: string) => {
	const { data } = useGetUserGetQuery({
		userid: userId,
		includecompany: true,
	});

	return data?.body?.companyUser?.rate ?? 1;
};
