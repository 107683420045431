import { SingleTab } from 'src/shared/ui/_tabs/lineTabs/LineTabs';

export const TABS: Array<SingleTab> = [
	{
		id: 'work',
		name: 'Рабочие',
	},
	{
		id: 'absence',
		name: 'Отсутствия',
	},
];
