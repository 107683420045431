import cn from 'classnames';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetGroupsByGroupIdQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { useGetGroupUserRights } from 'src/pages/vacation/ui/VacationPage/hooks/useGetGroupUserRights';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { User } from '../../../../../../types';
import { CreateVacationMC, CreateVacationModelData } from '../../../../../_modals/VacationMC';
import { SelectedYear } from '../../../../types';
import { CellInfo } from '../../types';
import { VacationBars } from '../VacationBar';
import s from './Table.module.scss';

let timeOut: any = null;

interface Props {
	users: {
		mainUser: User[] | undefined;
		otherUsers: User[];
	};
	selectedYear: SelectedYear;
}

export const Table: FC<Props> = props => {
	const {
		users, //
		selectedYear,
	} = props;

	// * Navigation
	const [searchParams] = useSearchParams();
	const groupId = searchParams.get('groupId');

	// * API
	const { data: groupData } = useGetGroupsByGroupIdQuery(
		{
			groupId: groupId ?? '',
		},
		{ skip: groupId === null },
	);

	const departmentId = groupData?.body?.departmentId ?? null;
	const projectId = groupData?.body?.projectId ?? null;

	// * Rights
	const { hasExtendedRights, activeUserId, isPlanConfirmed } = useGetGroupUserRights();
	const hasRightsToEdit = (userId: string) => (hasExtendedRights || activeUserId === userId) && !isPlanConfirmed && departmentId;

	const [startCellInfo, setStartCellInfo] = useState<CellInfo | null>(null);
	const [hoverCellInfo, setHoverCellInfo] = useState<CellInfo | null>(null);

	const onCellClick = (cellInfo: CellInfo, replace?: boolean) => {
		if (replace) {
			setStartCellInfo(cellInfo);
		} else if (startCellInfo) {
			const startDateLuxon = DateTime.fromObject({ year: startCellInfo.year, month: startCellInfo.month, day: startCellInfo.day });
			const endDateLuxon = DateTime.fromObject({ year: cellInfo.year, month: cellInfo.month, day: cellInfo.day });

			const startDateMS = startDateLuxon.toMillis();
			const clickedCellMS = endDateLuxon.toMillis();

			if (cellInfo?.rowIndex !== startCellInfo.rowIndex) {
				setStartCellInfo(cellInfo);
			} else if (clickedCellMS < startDateMS) {
				setStartCellInfo(cellInfo);
			} else {
				if (startCellInfo && cellInfo) {
					setVacationModal({
						userId: cellInfo.userId,
						startDate: startDateLuxon.toJSDate(),
						endDate: endDateLuxon.toJSDate(),
						leaveType: 'Vacation',
					});
				}
				setStartCellInfo(null);
			}
		} else {
			setStartCellInfo(cellInfo);
		}
	};

	// * Vacation modal
	const [vacationModal, setVacationModal] = useState<CreateVacationModelData | null>(null);
	const onVacationModalClose = () => {
		setVacationModal(null);
	};

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={Boolean(vacationModal)}
				onClose={onVacationModalClose}
				width="s"
			>
				{vacationModal && (
					<CreateVacationMC
						{...vacationModal}
						onClose={onVacationModalClose}
					/>
				)}
			</ModalNewProto>

			<div className={s.container}>
				<div className={s.calendar}>
					{selectedYear.months.map((month, monthIndex) => (
						<div
							key={month.name}
							className={cn(s.column, { [s.activeUser]: !!users.mainUser })}
						>
							<div className={s.header}>
								<div className={cn(s.row, s.row__month)}>{month.name}</div>
								<div className={s.row}>
									{Array.from(Array(month.holidays.length).keys()).map(dayIndex => (
										<div
											key={dayIndex}
											className={cn(s.cell, s.cell__day)}
										>
											{dayIndex + 1}
										</div>
									))}
								</div>
							</div>

							{Object.values(users).map(userList =>
								userList?.map((user, rowIndex) => (
									<div
										key={user.id}
										className={s.row}
									>
										<VacationBars
											user={user}
											monthIndex={monthIndex}
											selectedYear={selectedYear}
											users={userList ?? []}
											departmentId={departmentId}
											projectId={projectId}
											hasExtendedRights={hasExtendedRights}
											isPlanConfirmed={isPlanConfirmed}
										/>

										{month.holidays.split('').map((holidayBinary, dayIndex) => {
											const isHoliday = !!+holidayBinary;
											const cellInfo: CellInfo = { day: dayIndex + 1, month: monthIndex + 1, year: selectedYear.year, userId: userList[rowIndex].id, rowIndex };

											const isSelected =
												startCellInfo?.day === cellInfo.day && startCellInfo?.month === cellInfo.month && startCellInfo?.year === cellInfo.year && startCellInfo?.userId === cellInfo.userId;

											let isHighlighted = false;
											let replace = false;
											if (startCellInfo && hoverCellInfo) {
												const sameRowAsStart = startCellInfo?.rowIndex === hoverCellInfo?.rowIndex && cellInfo.rowIndex === hoverCellInfo?.rowIndex;

												if (sameRowAsStart) {
													const startDateMS = DateTime.fromObject({ year: startCellInfo.year, month: startCellInfo.month, day: startCellInfo.day }).toMillis();
													const hoverDateMS = DateTime.fromObject({ year: hoverCellInfo.year, month: hoverCellInfo.month, day: hoverCellInfo.day }).toMillis();
													const cellDateMS = DateTime.fromObject({ year: cellInfo.year, month: cellInfo.month, day: cellInfo.day }).toMillis();

													const inBetweenStartAndHover = cellDateMS > startDateMS && cellDateMS < hoverDateMS;

													isHighlighted = inBetweenStartAndHover;

													// const userVacations = MOCK_VACATIONS.find(vacation => vacation.user.id === user.id)?.vacations;
													const userVacations = user.vacations;

													const userVacationsMS = [];

													if (userVacations) {
														for (const vacation of userVacations) {
															userVacationsMS.push({
																startDateMS: DateTime.fromFormat(vacation.startDate, 'yyyy-MM-dd').toMillis(),
																endDateMS: DateTime.fromFormat(vacation.endDate, 'yyyy-MM-dd').toMillis(),
															});
														}
													}

													if (userVacationsMS.length) {
														const firstVacationAfterStart = userVacationsMS.find(vacation => vacation.startDateMS > startDateMS);
														if (firstVacationAfterStart) {
															if (hoverDateMS > firstVacationAfterStart.endDateMS) {
																isHighlighted = false;
																replace = true;
															}
														}
													}
												}
											}

											const onClick = () => onCellClick(cellInfo, replace);
											const onMouseOver = () => {
												clearTimeout(timeOut);
												!isSelected && setHoverCellInfo(cellInfo);
											};
											const onMouseLeave = () => {
												timeOut = setTimeout(() => setHoverCellInfo(null), 200);
											};

											return (
												// <Cell
												// 	key={dayIndex}
												// 	isSelected={isSelected}
												// 	isHighlighted={isHighlighted}
												// 	isHoliday={isHoliday}
												// 	onClick={onClick}
												// 	onMouseOver={onMouseOver}
												// 	onMouseLeave={onMouseLeave}
												// />
												<div
													key={dayIndex}
													className={cn(
														s.cell, //
														s.cell__filled,
														{ [s.cell__selectable]: hasRightsToEdit(user.id) },
														isSelected || isHighlighted ? s.cell__selectable_selected : isHoliday && s.cell__selectable_holiday,
													)}
													data-day={dayIndex}
													data-month={monthIndex}
													onClick={() => hasRightsToEdit(user.id) && onClick()}
													onMouseOver={onMouseOver}
													onMouseLeave={onMouseLeave}
												/>
											);
										})}
									</div>
								)),
							)}
						</div>
					))}
				</div>
			</div>
		</>
	);
};
