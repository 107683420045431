import { useSearchParams } from 'react-router-dom';
import { GroupResponse } from 'src/app/redux/queries/vacation-service/types/resTypes';
import { usePostGroupsByGroupIdConfirmationsMutation } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { Heading } from 'src/shared/ui/Heading';
import { getHref } from '../../../lib/getHref/getHref';
import s from './ConfirmPlanMC.module.scss';
import { TO_CONFIRM_MESSAGE } from './consts/consts';

interface Props {
	toggleModal: () => void;
	group: GroupResponse;
	refetchGroups: () => void;
}

export const ConfirmPlanMC: React.FC<Props> = props => {
	const {
		toggleModal, //
		group,
		refetchGroups,
	} = props;

	const [searchParams] = useSearchParams();
	const year = Number(searchParams.get('year'));

	// * API
	const [addConfirmation, { isLoading }] = usePostGroupsByGroupIdConfirmationsMutation();

	// * Submit
	const onSubmitClick = () => {
		addConfirmation({
			groupId: group.id,
			year,
		})
			.then(() => toggleModal())
			.finally(() => refetchGroups());
	};

	// * Render
	return (
		<>
			<Heading
				level={2}
				className={s.heading}
				marginBottom="l"
			>
				Утвердить план
			</Heading>

			<div className={s.info}>
				<div>
					Вы действительно хотите утвердить план отпусков
					<br />
					для{' '}
					<a
						className={s.link}
						href={getHref(group)}
						target="_blank"
						rel="noopener noreferrer"
					>
						{group.name}
					</a>
					?
				</div>

				<br />
				<div>{TO_CONFIRM_MESSAGE}</div>
			</div>

			<ButtonPair
				primaryText="Утвердить"
				secondaryText="Отмена"
				primaryOnClick={onSubmitClick}
				primaryIsLoading={isLoading}
				secondaryIsLoading={isLoading}
				secondaryOnClick={toggleModal}
			/>
		</>
	);
};
