import cn from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useActions } from 'src/app/redux/utils';
import { ReactComponent as CloseSVG } from 'src/shared/assets/svg/action/close.svg';
import { ReactComponent as WarningSVG } from 'src/shared/assets/svg_icons/alert/alert.svg';
import { ReactComponent as SuccessSVG } from 'src/shared/assets/svg_icons/alert/success.svg';
import { IconWrapper } from 'src/shared/ui/_wrappers/IconWrapper';
import { actionsNotifications } from '../_BLL/slice';
import { Notification, NotificationType } from '../_BLL/types';
import s from './NotificationSingle.module.scss';

export const NotificationSingle: React.FC<Notification> = memo(props => {
	const {
		id, //
		message,
		type,
	} = props;

	// * Styles
	const getIcon = (type: NotificationType) => {
		if (type === 'success') return <SuccessSVG className={s.icon_success} />;
		if (type === 'error') return <WarningSVG className={s.icon_error} />;
	};

	// * Actions
	const { deleteNotification } = useActions(actionsNotifications);

	// * Local state
	const [close, setClose] = useState(false);
	const [barWidth, setBarWidth] = useState<number>(0);
	const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);

	const onClose = () => {
		setClose(true);
		setTimeout(() => {
			deleteNotification({ notificationId: id });
		}, 400);
	};

	const handleStartTimer = useCallback(() => {
		const id = setInterval(
			() => {
				setBarWidth(prevState => {
					if (prevState < 100) {
						return prevState + 0.5;
					}
					return prevState;
				});
			},
			type === 'error' ? 60 : 25,
		);

		setIntervalId(id);
	}, []);

	const handlePauseTimer = useCallback(() => {
		clearInterval(intervalId as ReturnType<typeof setInterval>);
	}, [intervalId]);

	useEffect(() => {
		handleStartTimer();
	}, [handleStartTimer]);

	useEffect(() => {
		const handleCloseNotification = () => {
			handlePauseTimer();
			setClose(true);
			setTimeout(() => {
				deleteNotification({ notificationId: id });
			}, 400);
		};

		if (barWidth === 100) {
			handleCloseNotification();
		}
	}, [barWidth, deleteNotification, handlePauseTimer, id]);

	// * Render
	return (
		<div
			className={cn(s.container, close ? s.anim_close : s.anim_initialize)}
			onMouseEnter={handlePauseTimer}
			onMouseLeave={handleStartTimer}
		>
			<div className={s.content}>
				<div className={s.message}>
					<IconWrapper>{getIcon(type)}</IconWrapper>
					<p>{message}</p>
				</div>

				<CloseSVG
					className={s.close_icon}
					onClick={onClose}
				/>
			</div>

			<div
				className={cn(s.bar, s[`bar_${type}`])}
				style={{ width: `${barWidth}%` }}
			/>
		</div>
	);
});
