import { createColumnHelper } from '@tanstack/react-table';
import { ReactNode } from 'react';
import { Heading } from 'src/shared/ui/Heading';
import s from '../UsersListContainer.module.scss';

interface SubGroupUsersData {
	id: string;
	avatar: ReactNode;
	user: {
		name: string;
		position: string;
	};
	department: string;
	extra: ReactNode;
}

const columnHelper = createColumnHelper<SubGroupUsersData>();

export const COLUMNS = [
	columnHelper.accessor('avatar', {
		header: () => null,
		cell: info => info.getValue(),
		meta: {
			style: { width: '1%' },
		},
	}),
	columnHelper.accessor('user', {
		header: 'ФИО',
		cell: info => (
			<div>
				<Heading
					level={5}
					className={s.name}
				>
					{info.getValue().name}
				</Heading>
				<span className={s.position}>{info.getValue().position}</span>
			</div>
		),
	}),
	columnHelper.accessor('department', {
		header: 'ДЕПАРТАМЕНТ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('extra', {
		header: () => null,
		cell: info => info.getValue(),
		meta: {
			style: { width: '1%' },
		},
	}),
];
