import { DateTime } from 'luxon';
import { formatToUpperFirstLetter } from 'src/shared/lib/string';

// Date => [17.07 Пн, 18.07 Вт, 19.07 Ср...]
export const createDayList = (selectedDate: Date) => {
	const firstWeekDay = DateTime.fromJSDate(selectedDate).startOf('week');
	return Array.from(Array(7).keys()).map(index => {
		const day = firstWeekDay.plus({ day: index });
		return {
			name: `${day.toLocaleString({ day: '2-digit', month: '2-digit' })} ${formatToUpperFirstLetter(day.toLocaleString({ weekday: 'short' }, { locale: 'ru-RU' }))}`,
			dateLegacy: day.toJSDate(),
		};
	});
};
