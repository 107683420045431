import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetUserGetQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { usePutUsersByUserIdMutation } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { useRights } from 'src/shared/hooks/useRights';
import { NumberField } from 'src/shared/ui/_fields/NumberField';
import { Heading } from 'src/shared/ui/Heading';
import * as yup from 'yup';
import { useVacationPageContext } from '../../../context/useGroupUsersContext';
import { ERROR_MESSAGE } from './consts/consts';
import s from './LeftVacationDaysMC.module.scss';

interface FormValues {
	days: number;
}

interface Props {
	userId: string;
	toggleModal: () => void;
	refetchGroup: () => void;
}

export const LeftVacationDaysMC: React.FC<Props> = props => {
	const {
		userId, //
		toggleModal,
		refetchGroup,
	} = props;

	const [isError, setIsError] = useState(false);

	// * Rights
	const isAdmin = useRights();

	// * Context
	const { users } = useVacationPageContext();
	const user = users.find(user => user.id === userId);

	// * API
	const [updateRemainingVacationDays, { isLoading: updateLoading }] = usePutUsersByUserIdMutation();

	const { data: userData, isLoading: getUserLoading } = useGetUserGetQuery({
		userid: userId,
	});

	const entitledVacationDays = userData?.body?.userAddition?.entitledVacationDays;
	const isLoading = updateLoading || getUserLoading;

	// * Form
	const defaultValues = {
		days: user?.days.left ?? 0,
	};

	const schema = yup.object().shape({
		days: yup.number().nullable().min(0, '').max(99, ''),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch } = formMethods;
	const days = watch('days');

	useEffect(() => {
		if (!user?.days.used || !entitledVacationDays || !days) return;
		const newTotal = days + entitledVacationDays;
		return newTotal < user?.days.used ? setIsError(true) : setIsError(false);
	}, [days]);

	const onSubmit = ({ days }: FormValues) => {
		if (isError) return;
		if (user?.days.left === days) {
			toggleModal();
			return;
		}

		updateRemainingVacationDays({
			userId,
			userBody: {
				remainingVacationDays: days,
			},
		})
			.unwrap()
			.then(() => refetchGroup())
			.finally(() => toggleModal())
			.catch(e => console.log(e));
	};

	// * Render
	return (
		<FormProvider {...formMethods}>
			<form
				className={s.container}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Heading
					level={2}
					className={s.heading}
					marginBottom="l"
				>
					Неиспользованные
					<br /> дни отпуска
				</Heading>

				{isAdmin && (
					<div className={s.user}>
						<div className={s.user__label}>Пользователь</div>
						<span>{user?.name}</span>
					</div>
				)}

				<div className={s.input}>
					<NumberField
						name="days"
						numberType="integer"
						label="Количество дней"
						max={99}
						noNegative
						errorBorder={isError}
					/>
				</div>

				{isError && <div className={s.error}>{`${isAdmin ? 'У пользователя ' : 'У вас'} ${ERROR_MESSAGE}`}</div>}

				<ButtonPair
					primaryText="Сохранить"
					secondaryText="Отмена"
					primaryIsLoading={isLoading}
					secondaryIsLoading={isLoading}
					secondaryOnClick={toggleModal}
					primaryDisabled={!(typeof days === 'number') || isError}
				/>
			</form>
		</FormProvider>
	);
};
