import cn from 'classnames';
import React, { ComponentPropsWithoutRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LeftArrowSVG } from 'src/shared/assets/svg/navigation/Arrow_left_v1.svg';
import { ReactComponent as RightArrowSVG } from 'src/shared/assets/svg/navigation/Arrow_right_v1.svg';
import s from './NavRoute.module.scss';

interface Props extends ComponentPropsWithoutRef<'button'> {
	arrowDirection?: 'left' | 'right';
	route?: string;
	text: string;
}

export const NavRoute: React.FC<Props> = props => {
	const {
		arrowDirection = 'left',
		route,
		text,

		className,
		...divProps
	} = props;

	const navigate = useNavigate();

	const navigateTo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();
		route ? navigate(route) : navigate(-1);
	};

	// * Render
	return (
		<button
			{...divProps}
			className={cn(s.container, className)}
			onClick={navigateTo}
		>
			{arrowDirection === 'left' && <LeftArrowSVG />}
			{text}
			{arrowDirection === 'right' && <RightArrowSVG />}
		</button>
	);
};
