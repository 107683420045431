import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { useGetGroupsByGroupIdQuery, usePatchGroupsByGroupIdMutation } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { createFullName } from 'src/shared/lib/string';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { AddUserContainer } from '../../../AddUserContainer/AddUserContainer';
import { UsersListContainer } from '../../../UsersListContainer/UsersListContainer';
import s from '../../styles/Content.module.scss';
import { UserListItem } from 'src/features/_card_lists/types';

interface Props {
	refetchSubgroups: () => void;
}

export const TabUsers: React.FC<Props> = ({ refetchSubgroups }) => {
	const { groupId } = useParams() as { groupId: string };
	const [users, setUsers] = useState<UserListItem[]>([]);

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * API
	const [editUsers, { isLoading: editLoading }] = usePatchGroupsByGroupIdMutation();

	const { data: groupData, refetch } = useGetGroupsByGroupIdQuery({
		groupId,
	});

	const { data: usersData, isLoading: isLoadingGetUsers } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
		includepositions: true,
		isascendingsort: true,
	});

	const userIds = groupData?.body?.users?.map(user => user.id) || [];

	const allUsers =
		usersData?.body?.map(item => ({
			id: item.user.id,
			name: createFullName({ firstName: item.user.firstName, lastName: item.user.lastName, middleName: item.user.middleName ?? undefined }),
			avatar: item.user.avatar,
			department: item.departmentUser?.department.name,
			position: item.positionUser?.name,
		})) || [];

	useEffect(() => {
		setUsers(allUsers.filter(user => userIds.find(userId => user.id === userId)));
	}, [groupData, isLoadingGetUsers]);

	const onDelete = (userId: string) => {
		// ! remove не работает. Удаляет всех пользователей вместо одного, пока не починят на бэке используем replace.
		editUsers({
			groupId,
			body: [
				{
					op: 'replace',
					value: userIds.filter(existingUserId => existingUserId !== userId),
					path: '/UserIds',
				},
			],
		})
			.unwrap()
			.catch(e => console.log(e))
			.finally(() => {
				refetch();
				refetchSubgroups();
			});
	};

	const onEdit = (newUserIds: string[]) => {
		editUsers({
			groupId,
			body: [
				{
					op: 'replace',
					value: [...userIds, ...newUserIds],
					path: '/UserIds',
				},
			],
		})
			.unwrap()
			.then(() => toggleModal())
			.catch(e => console.log(e))
			.finally(() => {
				refetch();
				refetchSubgroups();
			});
	};

	// * Render
	return (
		<>
			{showModal && (
				<AddUserContainer
					showModal={showModal}
					toggleModal={toggleModal}
					editAction={onEdit}
					editLoading={editLoading || isLoadingGetUsers}
					allUsers={allUsers.filter(user => !userIds.find(existingUserId => existingUserId === user.id))}
				/>
			)}

			{isLoadingGetUsers ? (
				<LoaderCircle />
			) : (
				<div>
					<AddButton
						title="Добавить сотрудников"
						onClick={toggleModal}
						className={s.add_button_users}
					/>

					{users.length > 0 && (
						<UsersListContainer
							users={users}
							onRemoveUser={onDelete}
							isLoading={editLoading}
						/>
					)}
				</div>
			)}
		</>
	);
};
