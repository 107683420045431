import React, { FormEvent, HTMLAttributes, PropsWithChildren, useState } from 'react';
import { useFloatingWrapper } from 'src/shared/hooks/useFloatingWrapper';
import { v4 } from 'uuid';
import { Chip } from '../../../_chips/Chip/Chip';
import { OptionsWindow } from '../../../_option_lists/OptionsWindow/OptionsWindow';
import _input_with_options from '../../_styles/_input_with_options.module.scss';
import { TextInput } from '../../text_Inputs/TextInput';
import s from './SelectMultiWithSearch.module.scss';

interface Props<T, Key> extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
	label?: string;
	placeholder?: string;
	keyNames: {
		name: Key;
		value: Key;
	};
	searchSubstring: string;
	onStringChange: (value: string) => void;
	selectedOptions: T[];
	setSelectedOptions: (options: T[]) => void;
	options: T[];
	errorMessage?: string;
	disabled?: boolean;
	required?: boolean;
}

export const SelectMultiWithSearch = <T extends { [key: string]: any }, Key extends keyof T>(props: PropsWithChildren<Props<T, Key>>) => {
	const {
		label, //
		placeholder,
		keyNames,
		searchSubstring,
		onStringChange,
		selectedOptions,
		setSelectedOptions,
		options,
		errorMessage,
		disabled,
		required,
		...inputProps
	} = props;

	const inputId = `input_${v4()}`;

	// * User actions
	const [collapsed, setCollapsed] = useState(true);

	const onStringChangeHandler = (event: FormEvent<HTMLInputElement>) => {
		onStringChange(event.currentTarget.value);

		if (event.currentTarget.value === '') {
			setCollapsed(true);
		} else {
			setCollapsed(false);
		}
	};

	const onChange = (option: T) => {
		const optionExists = !!selectedOptions.find(prevOption => prevOption[keyNames.value] === option[keyNames.value]);
		if (!optionExists || selectedOptions.length === 0) {
			setSelectedOptions([...selectedOptions, option]);
		} else {
			setSelectedOptions(selectedOptions.filter(prevOption => prevOption[keyNames.value] !== option[keyNames.value]));
		}
	};

	const onStringEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		event.stopPropagation();
		setCollapsed(true);

		if (event.key === 'Enter') {
			event.preventDefault();

			const selectedOption = selectedOptions.find(selectedOption => selectedOption[keyNames.name].toLowerCase().trim() === searchSubstring.toLowerCase().trim());

			if (!selectedOption) {
				onStringChange('');
				const existingOption = options.find(option => option[keyNames.name].toLowerCase().trim() === searchSubstring.toLowerCase().trim());

				if (existingOption) {
					setSelectedOptions([...selectedOptions, existingOption as T]);
				} else {
					setSelectedOptions([
						...selectedOptions,
						{
							[keyNames.name]: searchSubstring,
							[keyNames.value]: `new_${v4()}`,
						} as T,
					]);
				}
			}
		}
	};

	// * Floating
	const { floatingStyles, refs, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(!collapsed, (value: any) => !disabled && setCollapsed(!value));

	// * Render
	return (
		<div className={_input_with_options.container}>
			<TextInput
				{...inputProps}
				onKeyDown={onStringEnter}
				label={label}
				placeholder={placeholder}
				value={searchSubstring}
				onChange={onStringChangeHandler}
				errorMessage={errorMessage}
				required={required}
				ref={refs.setReference}
				inputId={inputId}
				{...getReferenceProps()}
			/>

			<div
				className={_input_with_options.options}
				ref={refs.setFloating}
				style={{
					...floatingStyles,
					zIndex: 'var(--z-index-floating)',
				}}
				aria-labelledby={headingId}
				{...getFloatingProps()}
			>
				{!collapsed && (
					<OptionsWindow
						keyNames={keyNames}
						options={options}
						onOptionClick={onChange}
						selectedOptions={selectedOptions}
						inputId={inputId}
					/>
				)}
			</div>

			<div className={s.chip_container}>
				{selectedOptions.length > 0 &&
					selectedOptions.map(option => (
						<Chip
							key={option.id}
							text={option.name}
							variant="blue"
							handleClose={() => onChange(option)}
						/>
					))}
			</div>
		</div>
	);
};
