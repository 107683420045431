import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO } from 'src/shared/consts/consts';

const PARAMS = {
	month: 'month',
	year: 'year',
};

export const useSelectedDate = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const year = searchParams.get(PARAMS.year);
	const month = searchParams.get(PARAMS.month);

	const now = DateTime.fromJSDate(new Date());
	const initialDate = now
		.minus({ months: now.day <= LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO ? 1 : 0 })
		.startOf('month')
		.startOf('day')
		.toJSDate();

	useEffect(() => {
		if (!month) searchParams.set(PARAMS.month, initialDate.getMonth().toString());
		if (!year) searchParams.set(PARAMS.year, initialDate.getFullYear().toString());
		setSearchParams(searchParams);
	}, []);

	const setDate = (date: Date) => {
		searchParams.set(PARAMS.month, date.getMonth().toString());
		searchParams.set(PARAMS.year, date.getFullYear().toString());
		setSearchParams(searchParams);
	};

	const date = month && year ? DateTime.fromObject({ month: Number(month) + 1, year: Number(year) }).toJSDate() : initialDate;
	return { date, setDate };
};
