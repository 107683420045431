import { useState } from 'react';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { Button } from 'src/shared/ui/_buttons/Button';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { useGetGroupUserRights } from '../../hooks/useGetGroupUserRights';
import { AddEditGroupMC } from '../_modals/AddEditGroupMC';

export const Header = () => {
	const [groupModalOpen, setGroupModalOpen] = useState(false);

	const toggleGroupModal = () => {
		setGroupModalOpen(prevSate => !prevSate);
	};

	// * Rights
	const { hasExtendedRights } = useGetGroupUserRights();

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={groupModalOpen}
				onClose={toggleGroupModal}
				width="s"
			>
				<AddEditGroupMC closeModal={toggleGroupModal} />
			</ModalNewProto>

			<PageTitle
				title="Планировщик отпусков"
				buttons={[
					hasExtendedRights && (
						<Button
							key="button-1"
							variant="secondary"
							onClick={toggleGroupModal}
						>
							Добавить новую группу
						</Button>
					),
					<Button key="button-2">Личный кабинет 1С</Button>,
				]}
			/>
		</>
	);
};
