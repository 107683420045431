import { createContext, FC, ReactNode, useContext } from 'react';
import { User } from '../types';

interface Context {
	users: User[];
	setFilteredUsers: (users: User[]) => void;
	isLoading: boolean;
	activeUserId: string | null;
	setActiveUserId: (userId: string | null) => void;
	activeLeftDaysModal: string | null;
	setActiveLeftDaysModal: (userId: string | null) => void;
}

interface Props extends Context {
	children: ReactNode;
}

const VacationPageContext = createContext<Context | null>(null);

export const VacationPageContextProvider: FC<Props> = props => {
	const {
		users,
		setFilteredUsers,
		isLoading,
		activeUserId,
		setActiveUserId,
		activeLeftDaysModal,
		setActiveLeftDaysModal,

		children,
	} = props;

	// * Render
	return (
		<VacationPageContext.Provider
			value={{
				users,
				setFilteredUsers,
				isLoading,
				activeUserId,
				setActiveUserId,
				activeLeftDaysModal,
				setActiveLeftDaysModal,
			}}
		>
			{children}
		</VacationPageContext.Provider>
	);
};

export const useVacationPageContext = () => {
	const context = useContext(VacationPageContext);

	if (!context) {
		throw new Error('useVacationPageContext must be used within WorkTimesInfoContext');
	}

	return context;
};
