import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { VacationInfo, VacationTime } from 'src/app/redux/queries/vacation-service/types/types';
import {
	useDeleteVacationsByVacationIdMutation,
	useGetVacationsQuery,
	usePostVacationsMutation,
	usePutUsersByUserIdVacationsMutation,
} from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { useAppSelector } from 'src/app/redux/utils';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg_icons/action/delete.svg';
import { calculatePureHolidays, filterHolidaysByBinaryString, generateDatesByIsoInterval, getDaysSum } from 'src/shared/lib/date';
import { MonthHolidays } from 'src/shared/lib/date/calculateWorkDays/calculateWorkDays';
import { Button } from 'src/shared/ui/_buttons/Button';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { DateRangeField } from 'src/shared/ui/_fields/DateRangeField';
import { Heading } from 'src/shared/ui/Heading';
import { ValidationRequirements } from 'src/shared/ui/ValidationRequirements';
import * as yup from 'yup';
import { useVacationPageContext } from '../../../context/useGroupUsersContext';
import { getIntervalDescription } from '../../../lib/getIntervalDescription/getIntervalDescription';
import { getSeparatedPlannedVacations } from '../../../lib/getSeparatedPlannedVacations/getSeparatedPlannedVacations';
import { isDateOverlap } from '../../../lib/isDateOverlap/isDateOverlap';
import { useGetSelectedYearInfo } from '../../VacationTable/ui/VacationTable/useGetSelectedYear';
import s from './PlannedVacationMC.module.scss';

const VACATION_FIELD_NAME = 'vacations';

export interface FormValues {
	vacations: {
		startDate: Date | null;
		endDate: Date | null;
		id?: string;
	}[];
}
interface Props {
	userId: string;
	toggleModal: () => void;
	refetchGroup: () => void;
}

export const PlannedVacationMC: FC<Props> = props => {
	const {
		userId, //
		toggleModal,
		refetchGroup,
	} = props;

	const [validations, setValidation] = useState<{ total: boolean; atLeast: boolean }>({ total: false, atLeast: false });

	// * Selectors
	const activeUserId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	// * Context
	const { users } = useVacationPageContext();
	const user = users.find(user => user.id === userId);

	// * Navigation
	const [searchParams] = useSearchParams();
	// const groupId = searchParams.get('group');
	const year = searchParams.get('year');
	const selectedYearInfo = useGetSelectedYearInfo(Number(year));

	const minDate = `${year}-01-01`;
	const maxDate = `${year}-12-31`;

	// * API
	const {
		data: vacationData,
		isLoading: vacationIsLoading,
		isSuccess,
	} = useGetVacationsQuery(
		{
			startTime: minDate,
			endTime: maxDate,
			// groupId: groupId ?? undefined,
			userId,
		},
		{ skip: !userId },
	);

	const [createVacation, { isLoading: createVacationIsLoading }] = usePostVacationsMutation();
	const [editVacations, { isLoading: editVacationsIsLoading }] = usePutUsersByUserIdVacationsMutation();
	const [deleteVacation, { isLoading: deleteVacationIsLoading }] = useDeleteVacationsByVacationIdMutation();

	const isLoading = vacationIsLoading || createVacationIsLoading || deleteVacationIsLoading || editVacationsIsLoading;

	// * Праздничные дни
	let allMonthHolidays: MonthHolidays[] = [];
	let highlightedDates: Date[] = []; // Список праздничных дат в выбранном году.

	if (selectedYearInfo) {
		allMonthHolidays = selectedYearInfo.months.map((month, index) => ({
			holidays: month.holidays,
			month: index + 1,
			year: selectedYearInfo.year,
		}));

		highlightedDates = filterHolidaysByBinaryString(allMonthHolidays);
	}

	// * Form
	const defaultValues = {
		vacations:
			user?.vacations
				?.filter(vacation => vacation.type === 'Planned')
				?.map(({ id, startDate, endDate }) => ({
					id,
					startDate: DateTime.fromFormat(startDate, 'yyyy-MM-dd').toJSDate() ?? null,
					endDate: DateTime.fromFormat(endDate, 'yyyy-MM-dd').toJSDate() ?? null,
				})) ?? [],
	};

	const formMethods = useForm<FormValues>({
		defaultValues,
		resolver: yupResolver(
			yup.object().shape({
				vacations: yup.array().of(
					yup.object().shape({
						startDate: yup.date().nullable().required('Добавьте дату начала'),
						endDate: yup.date().nullable().required('Добавьте дату окончания'),
					}),
				),
			}),
		),
	});

	const { handleSubmit, watch, setError, control, formState, clearErrors } = formMethods;
	const { errors } = formState;
	const formValues = watch();

	const { fields, append, remove } = useFieldArray({
		control,
		name: VACATION_FIELD_NAME,
	});

	const newField = { startDate: null, endDate: null };

	const onFieldAdd = () => {
		append(newField);
	};

	const onFieldRemove = (index: number, totalFields: number) => {
		if (totalFields > 1) {
			remove(index);
		}
	};

	const vacations = formValues.vacations ?? [];

	const fieldsInfo = vacations.map(({ id, startDate, endDate }) => {
		const daysSum = startDate && endDate ? getDaysSum(startDate, endDate) : 0;
		const holidaysSum = startDate && endDate ? calculatePureHolidays(startDate, endDate, allMonthHolidays) : 0;
		const vacationsSum = daysSum - holidaysSum;

		return {
			id,
			vacationsSum,
			holidaysSum,
			startDateISO: startDate && (DateTime.fromJSDate(startDate).toISO() as string),
			endDateISO: endDate && (DateTime.fromJSDate(endDate).toISO() as string),
		};
	});

	const allFieldsSelectedDates = fieldsInfo.map(({ startDateISO, endDateISO }) => (startDateISO && endDateISO ? generateDatesByIsoInterval(startDateISO, endDateISO) : []));
	const totalDaysSelected = fieldsInfo.reduce((total, sums) => (total += sums.vacationsSum), 0);
	const availableVacationDays = users.find(user => user.id === userId)?.days.total ?? 0;

	useEffect(() => {
		if (isSuccess && vacationData) {
			const vacations = vacationData.body?.filter(vacation => vacation.vacationType === 'Planned');

			if (vacations?.length === 0 && fields.length === 0) {
				append(newField);
			}
		}
	}, [vacationData]);

	useEffect(() => {
		const atLeast = fieldsInfo.some(sum => sum.vacationsSum >= 14);
		const total = totalDaysSelected <= availableVacationDays;

		if (validations.atLeast !== atLeast || validations.total !== total) {
			setValidation({ total, atLeast });
		}
	}, [totalDaysSelected, fieldsInfo]);

	useEffect(() => {
		const message = isDateOverlap(vacations);
		return message ? setError(VACATION_FIELD_NAME, { message }) : clearErrors(VACATION_FIELD_NAME);
	}, [JSON.stringify(vacations)]);

	// * Manage vacations
	const onDeleteVacation = (vacationId: string) => {
		return deleteVacation({ vacationId }).unwrap();
	};

	const onCreateVacation = (vacationTimes: VacationTime[]) => {
		return createVacation({
			vacationRequest: {
				userId: userId,
				vacationType: 'Planned',
				vacationTimes,
			},
		}).unwrap();
	};

	const onEditVacations = (vacations: VacationInfo[]) => {
		return editVacations({
			userId,
			updateVacationRequest: {
				vacations,
			},
		}).unwrap();
	};

	// * Submit
	const onSubmit = (values: FormValues) => {
		if (errors.vacations?.message) {
			setError(VACATION_FIELD_NAME, { message: errors.vacations?.message });
			return;
		}

		const hasValidDates = values.vacations.every(({ startDate, endDate }) => startDate && endDate);

		if (validations.total && validations.atLeast && hasValidDates) {
			const promises = [];
			const { vacationsToDelete, vacationsToAdd, vacationsToEdit } = getSeparatedPlannedVacations(values.vacations, defaultValues.vacations);

			// * Удаление планируемых отпусков
			if (vacationsToDelete.length > 0) {
				vacationsToDelete.forEach(vacation => promises.push(onDeleteVacation(vacation.id)));
			}

			// * Создание планируемых отпусков
			if (vacationsToAdd.length > 0) {
				promises.push(onCreateVacation(vacationsToAdd));
			}

			// * Редактирование планируемых отпусков
			if (vacationsToEdit.length > 0) {
				promises.push(onEditVacations(vacationsToEdit));
			}

			Promise.all(promises)
				.catch(e => console.log(e))
				.then(() => refetchGroup())
				.finally(() => toggleModal());
		}
	};

	const renderFields = () => {
		return fields.map(({ id }, index, arr) => {
			const vacationsSum = fieldsInfo[index].vacationsSum;
			const holidaysSum = fieldsInfo[index].holidaysSum;

			return (
				<div key={id}>
					<span className={s.interval__header}>
						<span className={s.interval__title}>{index + 1} интервал отпуска</span>
						<IconButton
							Icon={<DeleteSVG />}
							onClick={() => onFieldRemove(index, arr.length)}
						/>
					</span>

					<DateRangeField
						className={s.interval__fields}
						labels={['Дата начала', 'Дата окончания']}
						startTimeName={`${VACATION_FIELD_NAME}.${index}.startDate`}
						endTimeName={`${VACATION_FIELD_NAME}.${index}.endDate`}
						minDate={DateTime.fromFormat(minDate, 'yyyy-MM-dd').toJSDate()}
						maxDate={DateTime.fromFormat(maxDate, 'yyyy-MM-dd').toJSDate()}
						highlightedDates={highlightedDates}
						excludeDates={allFieldsSelectedDates.filter((_, dateIndex) => dateIndex !== index).flat()}
					/>

					<span className={s.interval__description}>{getIntervalDescription(vacationsSum, holidaysSum)}</span>
				</div>
			);
		});
	};

	// * Render
	return (
		<div className={s.container}>
			<Heading
				className={s.heading}
				level={2}
				marginBottom="l"
			>
				{defaultValues.vacations.length > 0 ? 'Редактировать' : 'Внести'}
				<br /> планируемый отпуск
			</Heading>

			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ValidationRequirements
						className={s.requirements}
						requirements={[
							{
								text: 'Количество внесённых дней отпуска не может превышать количество доступных дней отпуска',
								isDone: validations.total,
							},
							{
								text: 'Хотя бы один из интервалов отпуска должен быть не менее 14 дней',
								isDone: validations.atLeast,
							},
						]}
					/>

					{activeUserId !== userId && (
						<div className={s.user}>
							<div className={s.user__label}>Пользователь</div>
							<div className={s.user__lalue}>{user?.name}</div>
						</div>
					)}

					<div className={s.available}>
						<div>
							<div className={s.available__label}>Доступно дней отпуска</div>
							<div className={s.available__value}>{availableVacationDays}</div>
						</div>

						<div>
							<div className={s.available__label}>Внесено дней отпуска</div>
							<div className={s.available__value}>{totalDaysSelected}</div>
						</div>
					</div>

					<div className={s.fields}>{renderFields()}</div>

					<Button
						className={s.interval__addButton}
						type="button"
						variant="secondary"
						onClick={onFieldAdd}
						wide
					>
						Добавить интервал отпуска
					</Button>

					<ButtonPair
						primaryText="Сохранить"
						secondaryText="Отмена"
						primaryIsLoading={isLoading}
						secondaryIsLoading={isLoading}
						secondaryOnClick={toggleModal}
					/>

					{errors?.vacations && <div className={s.error}>{errors.vacations.message}</div>}
				</form>
			</FormProvider>
		</div>
	);
};
