import cn from 'classnames';
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { ReactComponent as BetaSVG } from 'src/shared/assets/svg/beta.svg';
import { Heading } from 'src/shared/ui/Heading';
import { NavRoute } from 'src/shared/ui/NavRoute';
import s from './PageTitle.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	title: string;
	titleComponent?: ReactNode;
	goBackRoute?: {
		text: string;
		route?: string;
	};
	isBeta?: boolean;
	buttons?: ReactNode[];
}

export const PageTitle: React.FC<Props> = props => {
	const {
		title, //
		titleComponent,
		goBackRoute,
		isBeta,
		buttons = [],
		className,
		...divProps
	} = props;

	const hasButtons = buttons && buttons.length > 0;

	// * Render
	return (
		<div
			{...divProps}
			className={cn(s.container, className)}
		>
			{goBackRoute && (
				<NavRoute
					className={s.nav}
					{...goBackRoute}
				/>
			)}

			<div className={s.inner}>
				<div className={s.title}>
					<Heading
						level={1}
						size={goBackRoute ? 2 : 1}
					>
						{title}
					</Heading>
					{isBeta && <BetaSVG />}
					{titleComponent && titleComponent}
				</div>

				{hasButtons && <div className={s.buttons}>{buttons.map(Button => Button)}</div>}
			</div>
		</div>
	);
};
