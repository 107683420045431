import cn from 'classnames';
import React, { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';
import _input from 'src/shared/ui/_inputs/_styles/_input.module.scss';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';
import { InputLabel } from '../../_shared/InputLabel/InputLabel';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	variant?: 'line';
	value?: string | number | undefined;
	label?: string;
	type?: 'text' | 'number';
	maxLength?: number;
	errorBorder?: boolean;
	errorMessage?: string | null;
	characterLimit?: number;

	LeftIcon?: ReactNode;
	RightIcon?: ReactNode;
	onLeftIconClick?: React.MouseEventHandler<HTMLDivElement>;
	onRightIconClick?: React.MouseEventHandler<HTMLDivElement>;

	disabled?: boolean;
	isPickerPart?: boolean;
	defaultValue?: string | number | readonly string[];

	inputId?: string;
}

export const TextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
	const {
		errorBorder,
		className,
		value,
		label,
		type = 'text',
		maxLength,
		variant,
		errorMessage,
		characterLimit,

		LeftIcon,
		RightIcon,
		onLeftIconClick,
		onRightIconClick,

		disabled = false,
		isPickerPart = false,
		defaultValue,
		placeholder,
		onClick,
		onChange,
		required,
		inputId,
		...restInputProps
	} = props;

	// * Styles
	// - Classes
	const containers = cn(_input.container);
	const inputWrapperS = cn(_input.input_wrapper, isPickerPart && _input.isPicker, disabled && _input.disabled, className);
	const border = cn(variant === 'line' ? _input.border_bottom : _input.border, errorBorder && _input.border_error);

	// - Inline
	const ICON_WIDTH = 3.75; // rem
	const TEXT_LEFT_POSITION = 0.9375; // rem
	const inputWidth = `calc(100% - ${(Number(!!LeftIcon) + Number(!!RightIcon)) * ICON_WIDTH || 2}rem)`;
	const inputLeft = LeftIcon ? `${ICON_WIDTH - TEXT_LEFT_POSITION}rem` : undefined;

	const onClickHandler = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		event.stopPropagation();
		if (disabled) {
			event.preventDefault();
		} else if (onClick && !disabled) {
			onClick(event);
		}
	};

	const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			if (characterLimit) {
				const value = event.currentTarget.value;
				const sumOfCharacters = value.length;
				sumOfCharacters <= characterLimit && onChange(event);
			} else {
				onChange(event);
			}
		}
	};

	const onLeftIconClickHandler = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		event.stopPropagation();
		if (disabled) {
			event.preventDefault();
		} else if (onLeftIconClick && !disabled) {
			onLeftIconClick(event);
		}
	};

	const onRightIconClickHandler = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		event.stopPropagation();
		if (disabled) {
			event.preventDefault();
		} else if (onRightIconClick && !disabled) {
			onRightIconClick(event);
		}
	};

	// * Render
	return (
		<div className={containers}>
			<InputLabel
				label={label}
				required={required}
			/>

			<div
				className={inputWrapperS}
				onClick={onClickHandler}
				ref={ref}
			>
				{LeftIcon && (
					<div
						className={cn(_input.icon_left, (!!onClick || !!onLeftIconClick) && !disabled && _input.icon_left__clickable)}
						onClick={onLeftIconClickHandler}
					>
						{LeftIcon}
					</div>
				)}

				<input
					{...restInputProps}
					className={cn(_input.input)}
					style={{
						width: inputWidth,
						left: inputLeft,
					}}
					type={type}
					value={value === null || value === undefined ? '' : value}
					disabled={disabled}
					defaultValue={defaultValue}
					onChange={onChangeHandler}
					readOnly={isPickerPart}
					maxLength={maxLength}
					id={inputId} // для поиска input в DOM для навигации клавишами
				/>

				{RightIcon && (
					<div
						className={cn(_input.icon_right, (!!onClick || !!onRightIconClick) && !disabled && _input.icon_right__clickable)}
						onClick={onRightIconClickHandler}
					>
						{RightIcon}
					</div>
				)}

				<div className={border} />
				{(value === null || value === undefined || value === '') && (
					<span
						className={_input.placeholder}
						style={{
							width: inputWidth,
							left: inputLeft,
						}}
					>
						{placeholder}
					</span>
				)}
			</div>

			{(errorMessage || characterLimit) && (
				<ErrorWithLimit
					errorMessage={errorMessage}
					characterLimit={characterLimit}
					value={value}
					className={_input.error_message}
				/>
			)}
		</div>
	);
});
