import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { FixedSizeList as List, ListChildComponentProps, ListOnScrollProps } from 'react-window';
import { useWindowDimensions } from 'src/shared/hooks/useWindowDimensions';
import { Tooltip } from '../../_tooltips/Tooltip/Tooltip';
import { getItemSize } from './_helpers';
import s from './OptionsWindow.module.scss';

interface Props<T, Key> extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	keyNames: {
		name: Key;
		value: Key;
		tooltipText?: string;
	};
	options: T[];
	selectedOptions?: T[]; // ! Used in single/multi select only
	onOptionClick: (option: T) => void;
	width?: string;

	getScroll?: (getScroll: ListOnScrollProps) => void;
	scrollToItem?: number;
	fixedOption?: T;
	onFixedOptionClick?: () => void;

	inputId?: string;
}

export const OptionsWindow = <T extends Record<string, any> & { Icon?: ReactNode }, Key extends keyof T>(props: PropsWithChildren<Props<T, Key>>) => {
	const {
		className, //
		keyNames,
		options,
		onOptionClick,
		width = '100%',
		selectedOptions,
		getScroll,
		scrollToItem = 0,
		fixedOption,
		inputId,
		...divProps
	} = props;

	const isSelected = (index: number) => options.length > 0 && selectedOptions?.find(option => option[keyNames.value] === options[index]?.[keyNames.value]);
	const isShowAsSelected = (index: number, selectedIndex: number) => (isSelected(index) && selectedIndex === -1) || index === selectedIndex;

	const selectedItemToScroll = isSelected(scrollToItem) ? scrollToItem : 0;

	// * Refs
	const containerRef = useRef<HTMLDivElement | null>(null);
	const listRef = useRef<List | null>(null);
	const selectedIndexRef = useRef(selectedItemToScroll);
	const inputElementRef = useRef<HTMLInputElement | null>(null);

	const [selectedIndex, setSelectedIndex] = useState(selectedItemToScroll);

	const handleSelectedIndex = (newIndex: number) => {
		listRef.current?.scrollToItem(newIndex);
		selectedIndexRef.current = newIndex;
		return newIndex;
	};

	const Row = ({ index, style }: ListChildComponentProps) => (
		<div
			className={`${s.list_item} ${isShowAsSelected(index, selectedIndexRef.current) && s.selected_item}`}
			onClick={() => onOptionClick(options[index])}
			style={{
				...style,
				flex: 1,
			}}
		>
			{options[index].Icon && options[index].Icon}
			<span className={s.list_item_text}>
				<Tooltip content={options[index][keyNames.tooltipText ? keyNames.tooltipText : keyNames.name]}>
					<span>{options[index][keyNames.name]}</span>
				</Tooltip>
			</span>
		</div>
	);

	const { width: screenWidth } = useWindowDimensions();
	const itemCount = options ? options.length : 0;
	const itemSize = getItemSize(screenWidth);
	const maxItemsInWindow = 6;

	const noScroll = itemCount <= maxItemsInWindow;

	const getWindowHeight = () => {
		if (noScroll) {
			return itemCount * itemSize;
		} else {
			return maxItemsInWindow * itemSize;
		}
	};

	const handleInputKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			const index = selectedOptions && selectedOptions.length > 0 && options.findIndex(opt => opt[keyNames.value] === selectedOptions[selectedOptions.length - 1][keyNames.value]);
			const newIndex = !index || index === -1 ? 0 : index;
			listRef.current?.scrollToItem(newIndex);
			setSelectedIndex(newIndex);
			selectedIndexRef.current = newIndex;
			containerRef.current?.focus();
			containerRef.current?.addEventListener('keydown', handleKeyDown);
		}
	};

	const handleInputMouseEvent = (e: MouseEvent) => {
		inputElementRef.current?.focus();
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			setSelectedIndex(prev => handleSelectedIndex(prev === options.length - 1 ? prev : prev + 1));
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (selectedIndexRef.current === 0 && inputElementRef.current) {
				inputElementRef.current?.addEventListener('keydown', handleInputKeyDown);
				inputElementRef.current?.focus();
				setSelectedIndex(-1);
				selectedIndexRef.current = -1;
			} else {
				setSelectedIndex(prev => handleSelectedIndex(prev === 0 ? prev : prev - 1));
			}
		} else if (e.key === 'Enter' && selectedIndexRef.current !== -1) {
			e.preventDefault();
			onOptionClick(options[selectedIndexRef.current]);
		}
	};

	const findInputElement = () => {
		if (inputId) inputElementRef.current = document.getElementById(inputId) as HTMLInputElement;
	};

	useEffect(() => {
		findInputElement();
	}, []);

	useEffect(() => {
		if (inputElementRef.current) {
			selectedIndexRef.current = -1;
			setSelectedIndex(-1);
		}

		if (inputElementRef.current && selectedIndexRef.current === -1) {
			inputElementRef.current.focus();
			inputElementRef.current.addEventListener('keydown', handleInputKeyDown);
			inputElementRef.current.addEventListener('click', handleInputMouseEvent);
		} else if (containerRef.current) {
			containerRef.current.addEventListener('keydown', handleKeyDown);
			containerRef.current.focus();
		}

		return () => {
			if (inputElementRef.current) {
				inputElementRef.current.removeEventListener('keydown', handleInputKeyDown);
				inputElementRef.current.removeEventListener('click', handleInputMouseEvent);
			}
			if (containerRef.current) {
				containerRef.current.removeEventListener('keydown', handleKeyDown);
			}
		};
	}, [options]);

	// * Render
	return (
		<div
			ref={containerRef}
			className={`${s.container} ${className}`}
			style={{ width: width, display: itemCount === 0 ? 'none' : 'block' }}
			tabIndex={0}
			{...divProps}
		>
			{fixedOption && (
				<div className={`${s.list_item}`}>
					<span className={s.list_item_text}>
						<Tooltip
							content={fixedOption.name}
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{fixedOption.icon}
							<span>{fixedOption.name}</span>
						</Tooltip>
					</span>
				</div>
			)}

			<List
				ref={listRef}
				className={s.list}
				height={getWindowHeight()}
				itemCount={itemCount}
				itemSize={itemSize}
				width={width} // Minus left + right border & padding.
				onScroll={getScroll}
				initialScrollOffset={noScroll ? 0 : selectedItemToScroll * itemSize} // Only initial.
			>
				{Row}
			</List>
		</div>
	);
};
