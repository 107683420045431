import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { FC, useMemo, useState } from 'react';
import { ReactComponent as DownArrowSVG } from 'src/shared/assets/svg_icons/arrows/down_2.svg';
import { ReactComponent as UpArrowSVG } from 'src/shared/assets/svg_icons/arrows/up_2.svg';
import s from './CardsTable.module.scss';

// TODO: Запилить возможность фиксации высоты таблицы с возможностью скролла.

interface Props extends React.ComponentPropsWithoutRef<'table'> {
	columns: ColumnDef<any, any>[];
	data: any[];
	onRowClick?: (rowId: number) => void;
	sortingColumnId?: string;
	isAscendingSort?: boolean;
	handleSortChange?: () => void;
}

export const CardsTable: FC<Props> = props => {
	const {
		columns, //
		data,
		onRowClick,
		className,
		sortingColumnId,
		isAscendingSort: outerIsAscendingSort, // для сортировки по ФИО (директор и менеджеры остаются сверху)
		handleSortChange: outerHandleSortChange, // для сортировки по ФИО (директор и менеджеры остаются сверху)
		...tableProps
	} = props;

	// * Sorting
	const [isAscendingSort, setIsAscendingSort] = useState(true);
	const handleSortChange = () => setIsAscendingSort(prev => !prev);

	const isAscending = outerIsAscendingSort !== undefined ? outerIsAscendingSort : isAscendingSort;
	const handleChange = outerHandleSortChange || handleSortChange;

	const sortedData = useMemo(() => {
		if (!outerIsAscendingSort && sortingColumnId) {
			return isAscendingSort ? data : [...data].reverse();
		}
		return data;
	}, [data, isAscendingSort, outerIsAscendingSort, sortingColumnId]);

	const table = useReactTable({
		data: sortedData,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	// * Render
	return (
		<table
			{...tableProps}
			className={cn(s.table, onRowClick && s.table_clickable, className)}
		>
			<thead>
				{table.getHeaderGroups().map(headerGroup => (
					<tr
						key={headerGroup.id}
						// style={{ position: 'sticky', top: '-1rem' }}
					>
						{headerGroup.headers.map(header => (
							<th
								key={header.id}
								style={header.column.columnDef.meta?.style}
								onClick={header.id === sortingColumnId ? handleChange : undefined}
							>
								<div className={cn({ [s.sort_wrapper]: !!sortingColumnId, [s.clickable]: header.id === sortingColumnId })}>
									{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
									{header.id === sortingColumnId && (isAscending ? <UpArrowSVG /> : <DownArrowSVG />)}
								</div>
							</th>
						))}
					</tr>
				))}
			</thead>

			<tbody>
				{table.getRowModel().rows.map(row => (
					<tr
						key={row.id}
						onClick={event => {
							if (onRowClick) {
								event.stopPropagation();
								onRowClick(+row.id);
							}
						}}
					>
						{row.getVisibleCells().map(cell => {
							const hasMeta = cell.getContext().cell.column.columnDef.meta;

							return (
								<td
									key={cell.id}
									style={cell.column.columnDef.meta?.style}
									{...(hasMeta?.getCellContext && { ...hasMeta.getCellContext(cell.getContext()) })}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							);
						})}
					</tr>
				))}
			</tbody>
		</table>
	);
};
