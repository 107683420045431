import { SubgroupResponse } from 'src/app/redux/queries/vacation-service/types/resTypes';

interface OptionType {
	id: string;
	name: string;
}

export const getCategorizedSubgroups = (subgroups: SubgroupResponse[] | null | undefined) => {
	const categories: OptionType[] = [];
	const groups: OptionType[] = [];
	const teams: OptionType[] = [];

	subgroups?.forEach(({ type, id, name }) => {
		if (type === 'Category') categories.push({ id, name });
		else if (type === 'Group') groups.push({ id, name });
		else if (type === 'Team') teams.push({ id, name });
	});

	return { categories, groups, teams };
};
