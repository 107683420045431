import { Heading } from 'src/shared/ui/Heading';
import s from './SendEmailMC.module.scss';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { MCType } from '../../types';
import { UserListItem } from 'src/features/_card_lists/types';
import { UsersList } from '../UsersListContainer/UsersList';

interface FormValues {
	theme: string | null;
	content: string | null;
}

interface Props {
	allUsers: UserListItem[];
	toggleModal: () => void;
	setActiveMC: React.Dispatch<React.SetStateAction<MCType>>;
	removeUser: (userId: string) => void;
	selectedUserIds: string[];
}

export const SendEmailMC: React.FC<Props> = props => {
	const {
		allUsers, //
		toggleModal,
		setActiveMC,
		removeUser,
		selectedUserIds,
	} = props;

	const selectedUsers = selectedUserIds.map(userId => allUsers.find(user => user.id === userId)).filter(user => user !== undefined) as UserListItem[];

	console.log('selectedUsers :>> ', selectedUsers);

	const onCancelClick = () => {
		toggleModal();
	};

	const onAddButtonClick = () => {
		setActiveMC('addressee');
	};

	// * Form
	const defaultValues = {
		theme: '',
		content: '',
	};

	const schema = yup.object().shape({
		theme: yup.string().trim().required('Укажите тему письма'),
		content: yup.string().trim().required('Письмо не должно быть пустым'),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit } = formMethods;

	const onSubmit = (values: FormValues) => {
		console.log(values);
		console.log('selectedUsers :>> ', selectedUsers);
	};

	const primaryDisabled = selectedUsers.length === 0;

	// * Render
	return (
		<div>
			<Heading
				level={2}
				marginBottom="m"
			>
				Отправить e-mail
			</Heading>

			<FormProvider {...formMethods}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={s.form}
				>
					<h4>Письмо</h4>

					<div className={s.fields}>
						<StringField
							name="theme"
							label="Тема письма"
							placeholder="Введите тему письма"
						/>

						<TextAreaField
							name="content"
							label="Текст письма"
							placeholder="Введите текст письма"
							size="small"
						/>
					</div>

					<h4>Адресаты</h4>

					<AddButton
						className={s.addButton}
						title="Добавить адресата"
						onClick={onAddButtonClick}
					/>

					{selectedUsers.length > 0 && (
						<div className={s.users}>
							<UsersList
								users={selectedUsers}
								onRemoveUser={removeUser}
							/>
						</div>
					)}

					<ButtonPair
						className={s.buttons}
						primaryText="Сохранить"
						primaryIsLoading={false} // TODO
						primaryDisabled={primaryDisabled}
						secondaryText="Отмена"
						secondaryOnClick={onCancelClick}
						secondaryIsLoading={false} // TODO
					/>
				</form>
			</FormProvider>
		</div>
	);
};
