import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { usePostLeaveTimeCreateMutation } from 'src/app/redux/queries/time-service/time_serviceAPI';
import { useAppDispatch } from 'src/app/redux/utils';
import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { COMMENT_LIMIT } from 'src/pages/TimeTrackingPage/consts/consts';
import { getHours } from 'src/pages/TimeTrackingPage/ui/LeaveEnter/lib/getHours';
import { filterHolidaysByBinaryString } from 'src/shared/lib/date';
import { MonthHolidays } from 'src/shared/lib/date/calculateWorkDays/calculateWorkDays';
import { DateRangeField } from 'src/shared/ui/_fields/DateRangeField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { Heading } from 'src/shared/ui/Heading';
import { CREATE_EDIT_VACATION_SCHEMA } from '../../../consts/schemas';
import { useGetSelectedYearInfo } from '../../VacationTable/ui/VacationTable/useGetSelectedYear';
import s from './CreateVacationMC.module.scss';
import { useGetRate } from './hooks/useGetRate';
import { FieldValues, CreateVacationModelData } from './types';

interface Props extends CreateVacationModelData {
	onClose: () => void;
}

export const CreateVacationMC: FC<Props> = props => {
	const {
		userId, //
		startDate,
		endDate,
		leaveType,
		onClose,
	} = props;

	// * Routing
	const [searchParams] = useSearchParams();
	const year = searchParams.get('year');

	// * Year
	const selectedYearInfo = useGetSelectedYearInfo(Number(year));

	let allMonthHolidays: MonthHolidays[] = [];
	let highlightedDates: Date[] = []; // Список праздничных дат в выбранном году.

	if (selectedYearInfo) {
		allMonthHolidays = selectedYearInfo.months.map((month, index) => ({
			holidays: month.holidays,
			month: index + 1,
			year: selectedYearInfo.year,
		}));

		highlightedDates = filterHolidaysByBinaryString(allMonthHolidays);
	}

	// * API
	const [createLeaveTime, { isLoading: createLeaveTimeLoading }] = usePostLeaveTimeCreateMutation();

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	const isLoading = createLeaveTimeLoading;

	// * Form
	const defaultValues: FieldValues = {
		startTime: startDate,
		endTime: endDate,
		comment: null,
		leaveType,
	};

	const formMethods = useForm<FieldValues>({
		defaultValues,
		resolver: yupResolver(CREATE_EDIT_VACATION_SCHEMA),
	});

	const { handleSubmit, watch } = formMethods;

	const startTime = watch('startTime');
	const endTime = watch('endTime');

	// * Minutes
	const rate = useGetRate(userId);
	const hours = getHours(startTime, endTime, allMonthHolidays) * (rate ?? 1);
	const minutes = hours * 60;

	const onSubmit = (data: FieldValues) => {
		const startTime = data.startTime && DateTime.fromJSDate(data.startTime).toISO({ includeOffset: false });
		const endTime = data.endTime && DateTime.fromJSDate(data.endTime).toISO({ includeOffset: false });
		const comment = data.comment;
		const leaveType = data.leaveType;

		if (startTime && endTime) {
			createLeaveTime({
				createLeaveTimeRequest: {
					userId,
					startTime,
					endTime,
					minutes,
					leaveType,
					comment,
				},
			})
				.unwrap()
				.then(() => {
					dispatch(
						addNotification({
							type: 'success',
							message: 'Отсутствие успешно внесено.',
						}),
					);
					onClose();
				})
				.catch(e => console.log(e));
		}
	};

	// * Render
	return (
		<FormProvider {...formMethods}>
			<form
				className={s.container}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Heading
					className={s.heading}
					level={2}
				>
					Внести отпуск
				</Heading>

				<p className={s.description}>
					Информация об отпуске будет продублирована <br />в сервисе «Учёт времени» в разделе «Отсутствия».
				</p>

				<DateRangeField
					className={s.dates}
					labels={['Дата начала', 'Дата окончания']}
					startTimeName="startTime"
					endTimeName="endTime"
					minDate={DateTime.fromObject({ year: selectedYearInfo?.year }).startOf('year').toJSDate()}
					maxDate={DateTime.fromObject({ year: selectedYearInfo?.year }).endOf('year').toJSDate()}
					highlightedDates={highlightedDates}
				/>

				<TextAreaField
					className={s.comment}
					name="comment"
					label="Комментарий"
					placeholder="Введите комментарий"
					characterLimit={COMMENT_LIMIT}
				/>

				<ButtonPair
					primaryText="Внести"
					secondaryText="Отмена"
					primaryIsLoading={isLoading}
					secondaryIsLoading={isLoading}
					secondaryOnClick={onClose}
				/>
			</form>
		</FormProvider>
	);
};
