import React from 'react';
import { Button } from 'src/shared/ui/_buttons/Button';
import { Heading } from 'src/shared/ui/Heading';
import s from './SuccessContent.module.scss';

interface Props {
	toggleSuccessModal: () => void;
}

export const SuccessContent: React.FC<Props> = props => {
	const { toggleSuccessModal } = props;

	// * Render
	return (
		<div className={s.success_container}>
			<Heading level={3}>Отправлено</Heading>

			<div className={s.success_description}>
				Благодарим вас за обратную связь!
				<br />
				Наша команда займется вашим обращением
				<br />в ближайшее время.
			</div>

			<Button
				fixedWidth
				variant="primary"
				onClick={toggleSuccessModal}
			>
				Ок
			</Button>
		</div>
	);
};
