import React, { useState } from 'react';
import { useWorkTimesInfoContext } from 'src/app/contexts/useWorkTimesInfoContext';
import { useAppSelector } from 'src/app/redux/utils';
import { LeaveDeleteModal } from 'src/entities/_modals/LeaveDeleteModal/LeaveDeleteModal';
import { LeaveEditProps, LeaveEnter } from 'src/pages/TimeTrackingPage/ui/LeaveEnter/LeaveEnter';
import { COLUMNS_LEAVE_TIME } from 'src/pages/TimeTrackingPage/ui/Log/consts/COLUMNS_LEAVE_TIME';
import { ReactComponent as DeleteSVG } from 'src/shared/assets/svg/action/delete.svg';
import { ReactComponent as EditSVG } from 'src/shared/assets/svg/action/edit.svg';
import { ReactComponent as InfoSVG } from 'src/shared/assets/svg/alerts/info.svg';
import { LEAVE_TIME_OPTIONS } from 'src/shared/consts/options';
import { getMonthLeaveHours, isoToDateRangeString } from 'src/shared/lib/date';
import { Heading } from 'src/shared/ui/Heading';
import { IconButton } from 'src/shared/ui/_buttons/IconButton';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { TableWithComments } from 'src/shared/ui/_tables/TableWithComments';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip/Tooltip';
import { getHours } from '../LeaveEnter/lib/getHours';
import { LeaveTimeTableDataItem } from '../Log/_types';
import s from './LeaveLog.module.scss';

interface Props {
	userId: string;
	editingDisabled?: boolean;
}

export const LeaveLog: React.FC<Props> = props => {
	const {
		userId, //
		editingDisabled,
	} = props;

	// * Selectors
	const rate = useAppSelector(state => state.user_service.user.userInfo?.companyUser?.rate);

	// * Context
	const { leaveTimesInfo, workTimeMonthLimitInfo } = useWorkTimesInfoContext();

	const selectedDateLimitInfo = workTimeMonthLimitInfo[0]; // Выбранный месяц

	const { holidays, month } = selectedDateLimitInfo ?? {};

	// * Table data
	const leaveTimeTableData: LeaveTimeTableDataItem[] = leaveTimesInfo
		.filter(({ leaveTime: { isActive, managerLeaveTime } }) => !(!isActive && !managerLeaveTime)) // Убираем отсутствия удаленные пользователем.
		.map(({ leaveTime, user }) => {
			const {
				startTime, //
				endTime,
				id,
				leaveType,
				comment,
				managerInfo,
				managerLeaveTime,
				isActive,
			} = leaveTime;

			// if managerLeaveTime.isActive: false, this leave is not active.
			const isActiveLeave = managerLeaveTime ? Boolean(managerLeaveTime.isActive) : isActive;

			const initialLeaveType = LEAVE_TIME_OPTIONS.find(option => leaveType === option.id);

			// user hours
			const monthLeaveTimeByUser = getMonthLeaveHours(month, new Date(startTime), new Date(endTime), holidays, rate);
			const allLeaveTimeByUser = getHours(new Date(startTime), new Date(endTime), workTimeMonthLimitInfo);

			// manager hours or user hours
			const monthLeaveTime = managerLeaveTime
				? getMonthLeaveHours(month, new Date(managerLeaveTime.startTime), new Date(managerLeaveTime.endTime), holidays, rate)
				: monthLeaveTimeByUser;

			const allLeaveTime = managerLeaveTime
				? getHours(new Date(managerLeaveTime.startTime), new Date(managerLeaveTime.endTime), workTimeMonthLimitInfo) //
				: allLeaveTimeByUser;

			return {
				leaveType: initialLeaveType?.name ?? '',
				leaveDates: managerLeaveTime
					? isoToDateRangeString(managerLeaveTime.startTime ?? '', managerLeaveTime.endTime ?? '')
					: isoToDateRangeString(leaveTime.startTime, leaveTime.endTime),
				userHours: {
					current: monthLeaveTime,
					total: allLeaveTime * (rate ?? 1),
				},
				description: (managerLeaveTime ? managerLeaveTime.comment : comment) ?? null,
				isActive: isActiveLeave,
				extra: (
					<div className={s.tableButtons}>
						{!isActiveLeave ? (
							<Tooltip content={`Удалено: ${managerInfo?.firstName ?? user?.firstName} ${managerInfo?.lastName ?? user?.lastName}`}>
								<IconButton Icon={<InfoSVG />} />
							</Tooltip>
						) : (
							<>
								{managerLeaveTime && (
									<Tooltip content={`Отредактировано: ${managerInfo?.firstName} ${managerInfo?.lastName}`}>
										<IconButton Icon={<InfoSVG />} />
									</Tooltip>
								)}

								{!managerLeaveTime && !editingDisabled && (
									<>
										<IconButton
											Icon={
												<EditSVG
													onClick={() =>
														initialLeaveType &&
														setLeaveEditModalInfo({
															leaveTimeId: id,
															initialStartTime: startTime,
															initialEndTime: endTime,
															initialLeaveType,
															comment: comment ?? null,
														})
													}
												/>
											}
										/>

										<IconButton Icon={<DeleteSVG onClick={() => setLeaveDeleteModal(id)} />} />
									</>
								)}
							</>
						)}
					</div>
				),
				subRows:
					isActiveLeave && managerLeaveTime
						? [
								{
									leaveType: '',
									userHours: {
										current: monthLeaveTimeByUser,
										total: allLeaveTimeByUser * (rate ?? 1),
									},
									leaveDates: isoToDateRangeString(leaveTime.startTime, leaveTime.endTime),
									description: comment ?? null,
									isActive,
									extra: null,
								},
							]
						: undefined,
			};
		});

	// * Modal
	const [leaveEditModalInfo, setLeaveEditModalInfo] = useState<Required<Omit<LeaveEditProps, 'onCancel'>> | null>(null);
	const closeLeaveEditModal = () => setLeaveEditModalInfo(null);

	const [leaveDeleteModal, setLeaveDeleteModal] = useState<string | null>(null); // Хранит leaveTimeId если не null.
	const closeLeaveDeleteModal = () => setLeaveDeleteModal(null);

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={!!leaveEditModalInfo}
				onClose={closeLeaveEditModal}
				width="m"
			>
				{leaveEditModalInfo && (
					<div className={s.leaveModal}>
						<Heading
							level={2}
							marginBottom="l"
						>
							Редактирование часов
						</Heading>

						<LeaveEnter
							userId={userId}
							edit={{
								...leaveEditModalInfo,
								onCancel: closeLeaveEditModal,
							}}
						/>
					</div>
				)}
			</ModalNewProto>

			<LeaveDeleteModal
				leaveDeleteModal={leaveDeleteModal}
				closeLeaveDeleteModal={closeLeaveDeleteModal}
			/>

			<div>
				<Heading
					level={4}
					marginBottom="s"
				>
					Часы отсутствия
				</Heading>

				{leaveTimeTableData.length > 0 ? (
					<TableWithComments
						columns={COLUMNS_LEAVE_TIME}
						data={leaveTimeTableData}
					/>
				) : (
					<span>Нет данных об отсутствиях</span>
				)}
			</div>
		</>
	);
};
