import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators/page_decorators';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { VacationPageContextProvider } from '../../context/useGroupUsersContext';
import { useGetGroupUsers } from '../../hooks/useGetGroupUsers';
import { User } from '../../types';
import { LeftVacationDaysMC } from '../_modals/LeftVacationDaysMC/LeftVacationDaysMC';
import { PlannedVacationMC } from '../_modals/PlannedVacationMC';
import { Filters } from '../Filters';
import { Groups } from '../Groups';
import { Header } from '../Header';
import { SubHeader } from '../SubHeader';
import { VacationTable } from '../VacationTable';

export const VacationPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (!searchParams.get('groupId') && !searchParams.get('year')) {
			searchParams.set('groupId', 'null');
			searchParams.set('year', String(new Date().getFullYear().toString()));
			setSearchParams(searchParams);
		}
	}, []);

	const groupId = searchParams.get('groupId');

	const { isLoading, refetchGroup } = useGetGroupUsers(groupId);

	// * Planned vacation modal
	const [activeUserId, setActiveUserId] = useState<string | null>(null);
	const [activeLeftDaysModal, setActiveLeftDaysModal] = useState<string | null>(null);

	const closeUserModal = () => setActiveUserId(null);
	const closeLeftDaysModal = () => setActiveLeftDaysModal(null);

	// * Filtered users
	const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

	// * Render
	return (
		<PageDecorator>
			<VacationPageContextProvider
				users={filteredUsers}
				setFilteredUsers={setFilteredUsers}
				isLoading={isLoading}
				activeUserId={activeUserId}
				setActiveUserId={setActiveUserId}
				activeLeftDaysModal={activeLeftDaysModal}
				setActiveLeftDaysModal={setActiveLeftDaysModal}
			>
				{activeUserId && (
					<ModalNewProto
						isOpen={!!activeUserId}
						onClose={closeUserModal}
						width="s"
					>
						<PlannedVacationMC
							userId={activeUserId}
							toggleModal={closeUserModal}
							refetchGroup={refetchGroup}
						/>
					</ModalNewProto>
				)}

				{activeLeftDaysModal && (
					<ModalNewProto
						isOpen={!!activeLeftDaysModal}
						onClose={closeLeftDaysModal}
						width="s"
					>
						<LeftVacationDaysMC
							userId={activeLeftDaysModal}
							toggleModal={closeLeftDaysModal}
							refetchGroup={refetchGroup}
						/>
					</ModalNewProto>
				)}

				<Header />
				<Groups />
				<SubHeader />
				<Filters />
				<VacationTable />
			</VacationPageContextProvider>
		</PageDecorator>
	);
};
