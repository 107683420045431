import React, { useState } from 'react';
import { Button } from 'src/shared/ui/_buttons/Button';
import { InputsBlock } from '../../components/InputsBlock/InputsBlock';
import { OfferCard } from '../../components/OfferCard/OfferCard';
import { JOB_FORMAT, LABELS, REGISTRATION_FORM_OPTIONS, WORK_ADDRESS } from '../../consts/selectOptions';
import s from './Offer.module.scss';

export interface FieldBase {
	type?: 'textInput' | 'numberInput' | 'selectInput' | 'dateInput' | 'textArea';
	size?: string;
	label?: string;
	placeholder?: string;
	value: string;
	name?: string;
	id?: string;
	empty?: boolean;
	options?: Array<{ name: string; value: string }>;
}

export interface FieldTemplate {
	[key: string]: FieldBase[];
}

export interface SectionTitle {
	title?: string;
	isEditable: boolean;
	removeField: (section: string, index: number) => void;
	addField: (section: string, newField: FieldBase) => void;
	nameForField: string;
}

const initialDefaultValues: FieldTemplate = {
	formalization: [
		{
			name: 'formalizationField',
			type: 'textInput',
			size: 'tiny',
			label: 'Имя',
			placeholder: 'Введите имя',
			value: '',
		},
		{
			name: 'formalizationField',
			type: 'textInput',
			size: 'tiny',
			label: LABELS.jobTitle,
			placeholder: 'Введите должность',
			value: '',
		},
		{
			name: 'formalizationField',
			type: 'dateInput',
			size: 'tiny',
			label: LABELS.startDate,
			placeholder: 'ДД.ММ.ГГГГ',
			value: new Date().toDateString(),
		},
		{
			name: 'formalizationField',
			type: 'selectInput',
			size: 'tiny',
			label: LABELS.registrationType,
			placeholder: 'Введите или выберите из списка',
			value: REGISTRATION_FORM_OPTIONS[0].value,
			options: REGISTRATION_FORM_OPTIONS,
		},
		{
			name: 'formalizationField',
			type: 'textArea',
			size: 'tiny',
			label: LABELS.registration,
			placeholder: 'Заполните поле',
			value: 'в соответствии с ТК, ежегодный оплачиваемый отпуск 28 календарных дней.',
		},
		{
			name: 'formalizationField',
			label: LABELS.probationPayment,
			placeholder: 'Введите сумму',
			type: 'numberInput',
			size: 'tiny',
			value: '',
		},
		{
			name: 'formalizationField',
			label: LABELS.payment,
			placeholder: 'Введите сумму',
			type: 'numberInput',
			size: 'tiny',
			value: '',
		},
		{
			name: 'formalizationField',
			label: LABELS.probation,
			placeholder: 'Введите кол-во месяцев',
			type: 'textInput',
			value: '3 месяца',
		},
		{
			name: 'formalizationField',
			type: 'selectInput',
			size: 'tiny',
			label: LABELS.jobFormat,
			placeholder: 'Введите или выберите из списка',
			value: JOB_FORMAT[0].value,
			options: JOB_FORMAT,
		},
		{
			name: 'formalizationField',
			label: LABELS.schedule,
			placeholder: 'Заполните поле',
			type: 'textArea',
			size: 'tiny',
			value:
				'5/2, полный рабочий день. Обсуждается гибкое начало рабочего дня по согласованию с командой (часы работы бизнес-центра: 8:00 - 23:00), перерыв на обед – 1 час (плавающий).',
		},
		{
			name: 'formalizationField',
			type: 'selectInput',
			size: 'tiny',
			label: LABELS.mainAddress,
			placeholder: 'Введите или выберите из списка',
			value: WORK_ADDRESS[0].value,
			options: WORK_ADDRESS,
		},
	],
	possibility: [
		{
			type: 'textArea',
			name: 'possibilityField',
			value: 'Возможность посещать занятия по английскому/немецкому языкам;',
			size: 'tiny',
		},
		{
			type: 'textArea',
			name: 'possibilityField',
			value: 'Возможность участия в корпоративных спортивных (и не только) мероприятиях, спортивных соревнованиях;',
			size: 'tiny',
		},
		{
			type: 'textArea',
			name: 'endOfTrialField',
			value: 'Доступ к корпоративной библиотеке МИФ;',
			size: 'tiny',
		},
		{
			type: 'textArea',
			name: 'possibilityField',
			value: 'Еженедельные тренировки по пляжному волейболу;',
			size: 'tiny',
		},
		{
			type: 'textArea',
			name: 'possibilityField',
			value: 'Доступ к возможностям обучения в Академии «Ланит»;',
			size: 'tiny',
		},
		{
			type: 'textArea',
			name: 'possibilityField',
			value: 'Участие во внутренних технических семинарах, вебинарах;',
			size: 'tiny',
		},
	],
	endOfTrial: [
		{
			type: 'textArea',
			name: 'endOfTrialField',
			value: 'Полис ДМС (компания «Совкомбанк страхование»);',
			size: 'small',
		},
		{
			type: 'textArea',
			name: 'endOfTrialField',
			value: 'Частичная компенсация занятий фитнесом;',
			size: 'small',
		},
		{
			type: 'textArea',
			name: 'endOfTrialField',
			value: 'Возможность участия в профессиональных конференциях и форумах (при предварительном согласовании с Заказчиком и непосредственным руководителем);',
			size: 'small',
		},
		{
			type: 'textArea',
			name: 'endOfTrialField',
			value: 'Ежегодная возможность выбора обучения/конференции по профильному направлению;',
			size: 'small',
		},
		{
			type: 'textArea',
			name: 'endOfTrialField',
			value: 'Скидки от партнеров компании;',
			size: 'small',
		},
	],
	workToDo: [
		{
			type: 'textArea',
			name: 'workToDoField',
			value: '',
			placeholder: 'Заполните поле',
			empty: true,
			size: 'small',
		},
	],
	contacts: [
		{
			name: 'formalizationField',
			type: 'textInput',
			size: 'tiny',
			label: 'Контакты руководителя',
			placeholder: 'Введите ФИО и контакты руководителя',
			value: '',
		},
	],
};

export const Offer: React.FC = () => {
	const sectionsTitle: Omit<SectionTitle, 'addField' | 'removeField'>[] = [
		{
			isEditable: false,
			title: 'Условия сотрудничества',
			nameForField: 'formalization',
		},
		{
			isEditable: true,
			title: 'Возможности в рамках компании',
			nameForField: 'possibility',
		},
		{
			isEditable: true,
			title: 'По завершении испытательного срока',
			nameForField: 'endOfTrial',
		},
		{
			isEditable: true,
			title: 'Работы по проекту',
			nameForField: 'workToDo',
		},
		{
			isEditable: false,
			title: undefined,
			nameForField: 'contacts',
		},
	];

	const [offerInfo, setOfferInfo] = useState<FieldTemplate>(initialDefaultValues);
	const [submitPage, setSubmitPage] = useState<boolean>(false);

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setSubmitPage(true);
	};

	const resetForm = (value: FieldTemplate) => {
		setSubmitPage(false);
		setOfferInfo(value);
	};

	const addNewField = (section: string, newField: FieldBase) => {
		setOfferInfo(prevState => {
			const currentSection = [...prevState[section]];

			currentSection.push(newField);

			return {
				...prevState,
				[section]: currentSection,
			};
		});
	};

	const removeField = (section: string, index: number) => {
		setOfferInfo(prevState => {
			const currentSection = [...prevState[section]];

			currentSection.splice(index, 1);

			return {
				...prevState,
				[section]: currentSection,
			};
		});
	};

	const updateInputValue = (section: string, index: number, value: string, empty: boolean) => {
		setOfferInfo(prevState => {
			const currentSection = [...prevState[section]];

			currentSection[index] = { ...currentSection[index], value, empty };

			return {
				...prevState,
				[section]: currentSection,
			};
		});
	};

	const renderInputBlocks = (sections: Omit<SectionTitle, 'addField' | 'removeField'>[]) => {
		return sections.map(section => {
			return (
				<InputsBlock
					key={section.title}
					title={section.title}
					isEditable={section.isEditable}
					removeField={removeField}
					addField={addNewField}
					onChange={updateInputValue}
					nameForField={section.nameForField}
					renderData={offerInfo[section.nameForField]}
				/>
			);
		});
	};

	// * Render
	return (
		<div>
			{submitPage ? (
				<OfferCard
					data={offerInfo}
					resetOnClick={() => resetForm(initialDefaultValues)}
					goBackOnClick={() => setSubmitPage(false)}
				/>
			) : (
				<form
					onSubmit={onSubmit}
					className={s.form}
				>
					{renderInputBlocks(sectionsTitle)}

					<div className={s.buttonRow}>
						<Button
							variant="tertiary"
							onClick={e => {
								e.preventDefault();
								resetForm(initialDefaultValues);
							}}
							wide={true}
							type="button"
						>
							Сбросить и начать заново
						</Button>

						<Button
							variant="primary"
							wide={true}
							type="submit"
						>
							Создать макет
						</Button>
					</div>
				</form>
			)}
		</div>
	);
};
