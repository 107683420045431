import { useEffect } from 'react';
import { useGetUserFindQuery } from 'src/app/redux/queries/user-service/user_serviceAPI';
import { GroupUsers } from 'src/app/redux/queries/vacation-service/types/types';
import { useGetGroupsByGroupIdQuery, useGetGroupsByGroupIdSubgroupsAndSubgroupIdQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { UserListItem } from 'src/features/_card_lists/types';
import { createFullName } from 'src/shared/lib/string';

export const useGetSubGroupUsers = (groupId: string, subgroupId: string) => {
	// * API
	const {
		data: subGroupData,
		isLoading: isLoadingSubGroup,
		refetch: refetchSubGroup,
	} = useGetGroupsByGroupIdSubgroupsAndSubgroupIdQuery({
		groupId,
		subgroupId,
	});

	const { data: groupData, refetch: refetchGroup } = useGetGroupsByGroupIdQuery({
		groupId,
	});

	const { data, isLoading: isLoadingGetUsers } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		includepositions: true,
		isactive: true,
		includecurrentavatar: true,
	});

	useEffect(() => {
		refetchSubGroup();
		refetchGroup();
	}, [subgroupId]);

	const allUsers =
		data?.body?.map(item => ({
			id: item.user.id,
			name: createFullName({ firstName: item.user.firstName, lastName: item.user.lastName, middleName: item.user.middleName ?? undefined }),
			avatar: item.user.avatar,
			department: item.departmentUser?.department.name,
			position: item.positionUser?.name || '',
		})) || [];

	const subGroupType = subGroupData?.body?.type;

	const groupUsersIds = groupData?.body?.users?.map(user => user.id);
	const subGroupsUserIds = subGroupData?.body?.users?.map(user => user.id) || [];
	const otherSubgroupsUserIds = groupData?.body?.subgroups
		?.filter(subGroup => subGroup.id !== subgroupId)
		.filter(subGroup => subGroup.type === subGroupType)
		.flat()
		.map(subGroup => subGroup.users)
		.flat()
		.map(user => user?.id);

	const allGroupUsers = groupUsersIds?.map(groupUserId => allUsers.find(user => user.id === groupUserId)).filter(item => item !== undefined) as GroupUsers[]; // Все пользователи в группе.

	const usersInSubgroup: UserListItem[] = []; // Пользователи находящиеся в данной подгруппе.
	const usersNotInSubgroup: UserListItem[] = []; // Пользователи не находящиеся в какой-либо подгруппе данного типа.

	const sortedGroupUsers = allGroupUsers.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

	sortedGroupUsers?.forEach(user => {
		if (subGroupsUserIds.find(existingUserId => existingUserId === user?.id)) {
			usersInSubgroup.push(user as UserListItem);
		} else {
			// Фильтрация пользователей из других подгрупп
			if (!otherSubgroupsUserIds?.find(otherSubgroupUserId => otherSubgroupUserId === user?.id)) {
				usersNotInSubgroup.push(user as UserListItem);
			}
		}
	});

	return { usersInSubgroup, usersNotInSubgroup, isLoadingSubGroup, isLoadingGetUsers };
};
