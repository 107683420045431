import { DateTime } from 'luxon';
import { useState } from 'react';
import { WorkTimesInfoContextProvider } from 'src/app/contexts/useWorkTimesInfoContext';
import { useGetLeaveTimeFindQuery, useGetWorkTimeFindQuery, useGetWorkTimeMonthLimitFindQuery } from 'src/app/redux/queries/time-service/time_serviceAPI';
import { useAppSelector } from 'src/app/redux/utils';
import { TimeChart } from 'src/features/TimeChart';
import { LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO, MAX_APP_DATE, MIN_APP_DATE } from 'src/shared/consts/consts';
import { dateToFullRuDataNameString, getCurrentYearNumber, getMonthNumbers } from 'src/shared/lib/date';
import { CardWrapper } from '../CardWrapper/CardWrapper';
import s from './TimeTracking.module.scss';
import { Button } from 'src/shared/ui/_buttons/Button';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { getHasEditingRight } from './lib/getHasEditingRight';

export const TimeTracking = () => {
	// * Routing
	const navigate = useNavigate();

	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	const now = DateTime.fromJSDate(new Date());
	const initialDate = now
		.minus({ months: now.day <= LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO ? 1 : 0 })
		.startOf('month')
		.startOf('day')
		.toJSDate();

	const [selectedDate, setSelectedDate] = useState(initialDate);
	const handleSelectedDate = (date: Date) => {
		setSelectedDate(DateTime.fromJSDate(date).toJSDate());
	};

	const { currentMonth: month } = getMonthNumbers(selectedDate);

	const year = getCurrentYearNumber(selectedDate);

	const startTime = DateTime.fromJSDate(selectedDate).startOf('month').toISO();
	const endTime = DateTime.fromJSDate(selectedDate).endOf('month').toISO();

	// * API
	const { data: workTimeMonthLimitsRes } = useGetWorkTimeMonthLimitFindQuery(
		{
			year,
			month,
			takecount: 1000, // ! HARDCODE
			skipcount: 0, // ! HARDCODE
		},
		{ skip: !userId },
	);

	const { data: workTimesRes } = useGetWorkTimeFindQuery(
		{
			userid: userId,
			year,
			month,
			takecount: 1000, // ! HARDCODE
			skipcount: 0, // ! HARDCODE
		},
		{ skip: !userId },
	);

	const { data: leaveTimesRes } = useGetLeaveTimeFindQuery(
		{
			userid: userId,
			starttime: startTime,
			endtime: endTime,
			includedeactivated: true, // Получить удаленные записи отсутствий. isActive: false.
			takecount: 1000, // ! HARDCODE
			skipcount: 0, // ! HARDCODE
		},
		{ skip: !userId },
	);

	const workTimeInfo = workTimesRes?.body ? workTimesRes.body.map(item => item.workTime) : [];
	const workTimeMonthLimitInfo = workTimeMonthLimitsRes?.body ? workTimeMonthLimitsRes.body : [];
	const leaveTimesInfo = leaveTimesRes?.body ? leaveTimesRes.body : [];

	const isEmptyData = workTimeInfo.filter(info => !!info.userHours).length === 0 && leaveTimesInfo.filter(info => info.leaveTime.isActive && info.leaveTime.minutes).length === 0;

	const hasEditingRight = getHasEditingRight(month);

	const getInfoEditing = () => {
		if (!hasEditingRight) return undefined;

		const isPreMonth = new Date().getMonth() === month;

		return `Внесите отработанные часы до ${dateToFullRuDataNameString(new Date(year, month, isPreMonth ? 10 : 0))}.`;
	};

	// * Render
	return (
		<CardWrapper
			title="Учёт времени"
			path="/time"
			fullHeight
		>
			<WorkTimesInfoContextProvider
				workTimeInfo={workTimeInfo}
				workTimeMonthLimitInfo={workTimeMonthLimitInfo}
				leaveTimesInfo={leaveTimesInfo}
			>
				<TimeChart
					className={cn(s.container, !hasEditingRight && s.container__full_height)}
					selectedDate={selectedDate}
					setSelectedDate={handleSelectedDate}
					minDate={MIN_APP_DATE}
					maxDate={MAX_APP_DATE}
					customEmptyText={getInfoEditing()}
				/>
			</WorkTimesInfoContextProvider>

			{isEmptyData && hasEditingRight && (
				<Button
					fixedWidth
					className={s.button}
					onClick={() => navigate('/time')}
				>
					Внести часы
				</Button>
			)}
		</CardWrapper>
	);
};
